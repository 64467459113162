import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";
import { MDBDataTable } from "mdbreact";

import "../../../assets/css/datatables.css";
import EVOAlertComponent from "../../../components/Abstract/EvoAlertComponent";
import { ConsumerHelper } from "../../../common/ConsumerHelper";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";
// import EVOMDBDataTableHelper from "../../../common/evo.mdbDatatable-helper";

class ConsumerIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      instances: [],
      applications: [],
      loadedDatatable: false,
      loadedDatatablePublishedInstances: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    const consumerInstancesResult =
      await ApiCallerHelper.Consumer.getConsumerInstances("current");
    const consumerApplicationResult =
      await ApiCallerHelper.Consumer.getConsumerApplications("current");
    this.setState({
      applications: consumerApplicationResult.data.applications,
      instances: consumerInstancesResult.data,
      publishedInstancesData: this.getPublishedInstancesData(
        consumerInstancesResult.data
      ),
      dummy: this.getLoadedDatatable(
        consumerApplicationResult.data.applications
      ),
    });
  }

  getPublishedInstancesData(data) {
    let dataForTable = {
      columns: [
        {
          label: "Α/Α ▼",
          field: "SerialNumber",
          sort: "asc",
          width: 100,
        },
        {
          label: "Κατασταση ▼",
          field: "Status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ενεργειες ▼",
          field: "Buttons",
          sort: "asc",
          width: 150,
        },
        {
          label: "Παρατηρησεις ▼",
          field: "paratiriseis",
          sort: "asc",
          width: 150,
        },
      ],
      rows: data, // this.state.instances,
    };
    return dataForTable;
  }

  getLoadedDatatable(data) {
    let dataForTable = {
      columns: [
        {
          label: "Περιφερεια ▼",
          field: "state",
          sort: "asc",
          width: 100,
        },
        {
          label: "Πολη ▼",
          field: "city",
          sort: "asc",
          width: 150,
        },
        {
          label: "Διευθυνση ▼",
          field: "mainAddress",
          sort: "asc",
          width: 150,
        },
        {
          label: "Αρ. Διευθυνσης ▼",
          field: "addressNum",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ημερομηνια Υποβολης ▼",
          field: "dateCreated",
          sort: "asc",
          width: 150,
        },
        {
          label: "Επιθετο ▼",
          field: "LastName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ενεργειες ▼",
          field: "Buttons",
          sort: "asc",
          width: 150,
        },
      ],
      rows: data, //this.state.applications,
    };

    return dataForTable;
  }

  handleClick = async (appId) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      if (localStorage.getItem("token")) {
        axios
          .delete(
            process.env.REACT_APP_API_LINK + "/api/applications/" + appId + "/",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            window.location.reload(false);
          });
      }
    }
  };

  /**
   * Checks whether the complete application button is enabled based on custom flag and availability
   * @param {} part this application to be checked
   * @returns
   */
  isButtonCompleteApplicationInterestVisible = (part) => {
    // if (
    //   GlobalParametersHelper.getIsCustomFlorinaEnabled() &&
    //   part.city == 'Φλώρινα'
    // ){
    //   return true;
    // }
    let hasAvailability = part.availability == 1;
    return hasAvailability;
  };

  applicationInterest() {
    this.state.applications.forEach(function (part, index) {
      part.Buttons = (
        <React.Fragment key={index}>
          {this.isButtonCompleteApplicationInterestVisible(part) && (
            <Button
              color="success"
              href={"/consumer/application/" + part.Id}
              size="sm"
              title="Ολοκλήρωση"
            >
              Ολοκλήρωση
            </Button>
          )}
          <Button
            color="danger"
            onClick={() => this.handleClick(part.Id)}
            size="sm"
            title="Διαγραφή"
          >
            <i className="fas fa-trash" />
          </Button>
        </React.Fragment>
      );
    }, this);
  }

  publishedInstances() {
    this.state.instances.forEach(function (part, index) {
      this[index].Buttons = (
        <React.Fragment key={index}>
          <Button
            color="primary"
            href={"/consumer/instance/" + this[index].Id}
            size="sm"
          >
            Έλεγχος πορείας
          </Button>
          <Button
            color="primary"
            href={"/consumer/instancedetails/" + this[index].Id}
            size="sm"
          >
            Στοιχεία αίτησης
          </Button>
          <Button
            color="primary"
            href={"/consumer/instancedocuments/" + this[index].Id}
            size="sm"
          >
            Έγγραφα αίτησης
          </Button>
        </React.Fragment>
      );

      this[index].paratiriseis = (
        <React.Fragment>
          <ConsumerHelper.GetInstanceMessageToClient instance={part} />
        </React.Fragment>
      );
    }, this.state.instances);
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--9" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Υποβεβλημένες αιτήσεις</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/consumer/instances"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {this.publishedInstances()}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        data={this.state.publishedInstancesData}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]}
                        entries={10}
                        searchLabel="Αναζήτηση"
                        paginationLabel={["Προηγούμενο", "Επόμενο"]}
                        infoLabel={[
                          "Εμφανίζονται",
                          "έως",
                          "από",
                          "καταχωρήσεις",
                        ]}
                        entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                      />
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Εκδηλώσεις ενδιαφέροντος</h3>
                    </div>
                    <div className="col text-right"></div>
                  </Row>
                </CardHeader>
                {this.applicationInterest()}
                {/* { this.state.loadedDatatable == false ? this.applicationInterest() : <></> } */}

                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        data={this.state.dummy}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]}
                        entries={10}
                        searchLabel="Αναζήτηση"
                        paginationLabel={["Προηγούμενο", "Επόμενο"]}
                        infoLabel={[
                          "Εμφανίζονται",
                          "έως",
                          "από",
                          "καταχωρήσεις",
                        ]}
                        entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                      />
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <div className="push"></div>
        </Container>
      </>
    );
  }
}

export default ConsumerIndex;
