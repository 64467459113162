import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  
export async function getConsumerTypeByInstanceId(instanceId) {
  var route = "/api/consumer/type/" + instanceId + "/";
  return getAsync(route);
}

export async function getConsumerInstances(consumerId) {
  var route = "/api/consumer/instances/" + consumerId + "/";
  return getAsync(route);
}

export async function getConsumerApplications(consumerId) {
  var route = "/api/consumer/applications/" + consumerId + "/";
  return getAsync(route);
}

export async function getConsumerRecentInstance() {
  var route = "/api/consumer/recent/instance/";
  return getAsync(route);
}