import React from "react";
import {
  EVOReactTableHelper,
  FooterCellCalculationType,
  DynamicCellItemType,
} from "./evo.react-table-helper";
import { Clone } from "../../common/utils";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";

export const EVOReactTableDynamicGridHelper = {
  DynamicAction: {
    isSkip: (stepActionData) => {
      return (
        stepActionData === null ||
        stepActionData.Type !== DynamicStepActionTypes.dynamicReactTable
      );
    },
    getDatasetAndColumnDefinitionsFromStepActionsData: (stepActionData) => {
      let metaData = JSON.parse(stepActionData?.MetaDataJson ?? "null");
      let defaultMetaData =
        EVOReactTableDynamicGridHelper.DynamicAction.getDefaultStepActionMetaDataModel();
      if (metaData === null) {
        metaData = defaultMetaData;
      }
      metaData.reactTable = {
        ...defaultMetaData.reactTable,
        ...metaData.reactTable,
      };
      metaData.reactTable.columnMetadataDefinitions =
        defaultMetaData.reactTable.columnMetadataDefinitions;
      return metaData;
    },
    getDefaultStepActionMetaDataModel: () => {
      return {
        reactTable: {
          columnMetadataDefinitions: [
            {
              id: "columnId",
              title: "Κωδικός Πεδίου",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnTitle",
              title: "Τίτλος",
              type: DynamicCellItemType.text,
              isEditable: true,
              defaultNewRowValue: "Τίτλος",
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnType",
              title: "Τύπος",
              type: DynamicCellItemType.react_select,
              isEditable: true,
              displayKey: "label",
              valueKey: "value",
              options: [
                {
                  value: DynamicCellItemType.action,
                  label: DynamicCellItemType.action,
                },
                {
                  value: DynamicCellItemType.text,
                  label: DynamicCellItemType.text,
                },
                {
                  value: DynamicCellItemType.number,
                  label: DynamicCellItemType.number,
                },
                {
                  value: DynamicCellItemType.integer,
                  label: DynamicCellItemType.integer,
                },
                {
                  value: DynamicCellItemType.checkbox,
                  label: DynamicCellItemType.checkbox,
                },
                {
                  value: DynamicCellItemType.react_select,
                  label: DynamicCellItemType.react_select,
                },
                {
                  value: DynamicCellItemType.cell_template,
                  label: DynamicCellItemType.cell_template,
                },
              ],
              defaultNewRowValue: DynamicCellItemType.text,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnFooterCalculationType",
              title: "Τέλος Κολόνας",
              type: DynamicCellItemType.react_select,
              isEditable: true,
              displayKey: "label",
              valueKey: "value",
              options: [
                { value: "", label: " - " },
                {
                  value: FooterCellCalculationType.count,
                  label: FooterCellCalculationType.count,
                },
                {
                  value: FooterCellCalculationType.sum,
                  label: FooterCellCalculationType.sum,
                },
              ],
              width: 150,
              minWidth: 150,
              defaultNewRowValue: "",
            },
            {
              id: "columnIsEditable",
              title: "Επεξ/σία",
              type: DynamicCellItemType.checkbox,
              isEditable: true,
              defaultNewRowValue: true,
            },
            {
              id: "columnWidth",
              title: "Πλάτος",
              type: DynamicCellItemType.number,
              isEditable: true,
              width: 50,
            },
            {
              id: "columnDefaultNewRowValue",
              title: "Νέα Τιμή",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnOnAfterChangeValueRecalculateColumns",
              title: "* Ανανέωση Πεδίων",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnOnCalculateValue",
              title: "* Συνάρτηση Υπολογισμού",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnΟnAfterChangeValue",
              title: "* Συνάρτηση μετά",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnOptions",
              title: "* Πηγη Λίστας",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
            },
            {
              id: "columnOptionsValueKey",
              title: "* Πεδιο Κλειδιού Λίστας",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
              defaultNewRowValue: "value",
            },
            {
              id: "columnOptionsDisplayKey",
              title: "* Πεδιο Εμφάνισης Λίστας",
              type: DynamicCellItemType.text,
              isEditable: true,
              width: 200,
              minWidth: 200,
              defaultNewRowValue: "label",
            },
            {
              id: "__action",
              title: "Ενέργεια",
              type: "action",
              showDelete: true,
              width: 50,
              minWidth: 50,
            },
          ],
          initialDataset: [],
          dataset: [],
        },
      };
    },
    getGridDefinitionByColumnstepActionDataValue: (stepActionData) => {
      let metaData = JSON.parse(stepActionData?.MetaDataJson ?? "null");
      if (metaData === null) return [];
      return EVOReactTableDynamicGridHelper.DynamicAction.getGridDefinitionByColumnMetadataValue(
        metaData
      );
    },
    getGridDefinitionByColumnMetadataValue: (metaData) => {
      let columnMetaDataDefinitionsData = metaData.reactTable.dataset;
      let columnMetaDataDefinitions = [];
      columnMetaDataDefinitionsData.forEach((element) => {
        let item = ColumnDefinitionMetadataFactory.getNew();
        item.id = element.columnId;
        item.isEditable = element.columnIsEditable;
        item.title = element.columnTitle;
        item.type = element.columnType;
        item.defaultNewRowValue = element.columnDefaultNewRowValue;

        item.footerCalculationType = element.columnFooterCalculationType;

        if (element.columnWidth) {
          item.minWidth = Number(element.columnWidth);
          item.maxWidth = Number(element.columnWidth);
          item.width = Number(element.columnWidth);
        }
        if (item.width == 0) {
          item.show = false;
        }

        if (element.columnOptionsValueKey) {
          item.valueKey = element.columnOptionsValueKey;
        }

        if (element.columnOptionsDisplayKey) {
          item.displayKey = element.columnOptionsDisplayKey;
        }

        if (element.columnOptions) {
          try {
            item.options = JSON.parse(element.columnOptions);
            if (Array.isArray(item.options) === false) {
              throw new Error("Invalid array in columnOptions");
            }
          } catch (e) {
            item.options = [];
            alert(e);
          }
        }
        if (element.optionsFilterSourceField) {
          item.optionsFilterSourceField = element.optionsFilterSourceField;
        }
        if (element.optionsFilterTargetField) {
          item.optionsFilterTargetField = element.optionsFilterTargetField;
        }

        if (element.columnOnAfterChangeValueRecalculateColumns) {
          try {
            item.onAfterChangeValueRecalculateColumns = JSON.parse(
              element.columnOnAfterChangeValueRecalculateColumns
            );
            if (Array.isArray(item.options) === false) {
              throw new Error("Invalid array in columnOptions");
            }
          } catch (e) {
            item.options = [];
            alert(e);
          }
        }

        if (element.columnOnCalculateValue) {
          try {
            let newFunction = new Function("e", element.columnOnCalculateValue);
            item.onCalculateValue = newFunction;
          } catch (e) {
            item.onCalculateValue = null;
            alert(e);
          }
        }

        if (element.columnΟnAfterChangeValue) {
          try {
            let newFunction = new Function(
              "e",
              element.columnΟnAfterChangeValue
            );
            item.onAfterChangeValue = newFunction;
          } catch (e) {
            item.onAfterChangeValue = null;
            alert(e);
          }
        }

        if (
          item.type === DynamicCellItemType.action ||
          item.id === "__action"
        ) {
          item = {};
          item.type = "action";
          item.id = "__action";
          item.width = 50;
          item.minWidth = 50;
          item.title = element.columnTitle;
          item.showDelete = true;
        }
        columnMetaDataDefinitions.push(item);
      });

      return columnMetaDataDefinitions;
    },
  },

  getReactTableColumnDefinition: getReactTableColumnDefinition,
  getColumnDefinitionMetadataById: getColumnDefinitionMetadataById,
  getCellName: getCellName,
};

export default EVOReactTableDynamicGridHelper;

const ColumnDefinitionMetadataFactory = {
  getNew: () => {
    return {
      id: "",
      title: "",
      type: "text",
      isEditable: false,
      maxWidth: undefined,
      minWidth: undefined,
      width: undefined,
      options: [],
      optionsFilterSourceField: "",
      optionsFilterTargetField: "",
      displayKey: "label",
      valueKey: "value",
      rowTemplate: null,
      defaultNewRowValue: null,
      onAfterChangeValue: null,
      onCalculateValue: null,
      onAfterChangeValueRecalculateColumns: null,
    };
  },
};

/**
 * Generates react table column definitions from column definition meta data
 */
function getReactTableColumnDefinition(columnMetadataDefinitions, isEditable) {
  if (isEditable === null) {
    isEditable = true;
  }

  function _getNewColumnDefinition(options) {
    var defaultOptions = {
      header: "",
      accessor: "",
      footerCalculationType: null,
    };
    var opt = { ...defaultOptions, ...options };

    let result = {
      Header: opt.header,
      accessor: opt.accessor,
      //maxWidth: opt.maxWidth,
      minWidth: opt.minWidth,
      width: opt.width,
      show: opt.show,
      _style: opt.style,
      _headerStyle: opt.headerStyle,
      _footerStyle: opt.footerStyle,
    };

    if (opt.footerCalculationType != null) {
      result.Footer = (data) => {
        let footerCalculationType = "";
        if (typeof opt.footerCalculationType === "string") {
          footerCalculationType = opt.footerCalculationType;
        } else {
          footerCalculationType = opt.footerCalculationType?.value ?? null;
        }

        if (typeof footerCalculationType !== "string") {
          return <></>;
        }
        if (footerCalculationType === FooterCellCalculationType.sum) {
          const sum = EVOReactTableHelper.ReactTable.Sum(data, opt.accessor);
          return <>Σύνολο: {sum}</>;
        }
        if (footerCalculationType === FooterCellCalculationType.count) {
          const count = EVOReactTableHelper.ReactTable.Count(data, opt.count);
          return <>Πλήθος: {count}</>;
        }
      };
    }

    return result;
  }

  if (!columnMetadataDefinitions) {
    return [];
  }

  let columns = [];
  columnMetadataDefinitions.forEach((element) => {
    if (isEditable === false) {
      element.isEditable = isEditable;
    }
    let item = _getNewColumnDefinition({
      header: element.title,
      accessor: element.id,
      ...element,
    });
    columns.push(item);
  });
  return columns;
}

/**
 * Gets column definition metadata by id
 */
function getColumnDefinitionMetadataById(id, columnMetadataDefinitions) {
  return (
    columnMetadataDefinitions.filter((x) => x.id === id)[0] ??
    ColumnDefinitionMetadataFactory.getNew()
  );
}

function getCellName(rowIndex, id) {
  return id + "_" + rowIndex;
}
export const GridActions = {
  getUpdatedDataSourceAndColumnMetaDataDefinitions: (
    data,
    rowIndex,
    columnId,
    value,
    columnMetadataDefinitions
  ) => {
    let newValues = data.map(function (row, index) {
      //return row unchanged
      if (index !== rowIndex) {
        return row;
      }
      var newRow = { ...data[rowIndex], [columnId]: value };
      setRowState(newRow, RowState.UPDATED);
      let columnMetaDataDefinition = columnMetadataDefinitions.filter(
        (x) => x.id === columnId
      )[0];
      var selectedOptionItem =
        columnMetaDataDefinition?.options?.filter(
          (x) =>
            x[columnMetaDataDefinition.valueKey ?? ""] ===
              newRow[columnId]?.value ?? ""
        )[0] ?? {};

      //execute onAfterChangeValue if exist
      function executeOnAfterChangeValue(row, columnMetaDataDefinition) {
        if (columnMetaDataDefinition.onAfterChangeValue === null) {
          return;
        }
        //get option selected value if it exists
        //execute column definition onAfterChangeValue and pass any required data
        if (typeof columnMetaDataDefinition.onAfterChangeValue === "function") {
          columnMetaDataDefinition.onAfterChangeValue({
            row: row,
            columnMetadataDefinitions: columnMetadataDefinitions,
            columnMetaDataDefinition: columnMetaDataDefinition,
            selectedOptionItem: selectedOptionItem,
          });
        }
      }

      function executeOnCalculateValue(row, columnMetaDataDefinition) {
        let onAfterChangeValueRecalculateColumns =
          columnMetaDataDefinition.onAfterChangeValueRecalculateColumns;
        if (!onAfterChangeValueRecalculateColumns) {
          return;
        }

        onAfterChangeValueRecalculateColumns.forEach((field) => {
          let columnDefinition = columnMetadataDefinitions.filter(
            (x) => x.id === field
          )[0];
          if (typeof columnDefinition.onCalculateValue === "function") {
            columnDefinition.onCalculateValue({
              row: row,
              columnMetadataDefinitions: columnMetadataDefinitions,
              columnMetaDataDefinition: columnMetaDataDefinition,
              selectedOptionItem: selectedOptionItem,
            });
          }
        }, this);
      }
      executeOnAfterChangeValue(newRow, columnMetaDataDefinition);
      executeOnCalculateValue(newRow, columnMetaDataDefinition);

      return newRow;
    }, this);
    return {
      data: newValues,
      columnMetadataDefinitions: columnMetadataDefinitions,
    };
  },
  getDatasourceAndDeletedItemByDeleteRowIndex: (data, rowIndex) => {
    const dataCopy = [...data];
    let clonedDeletedItem = Clone(data[rowIndex]);
    setRowState(clonedDeletedItem, RowState.DELETED);
    dataCopy.splice(rowIndex, 1);
    return { data: dataCopy, deletedItem: clonedDeletedItem };
  },
  getDatasourceWithNewRow: (
    data,
    columnMetadataDefinitions,
    optionalEnforcedRowDataValues
  ) => {
    const tableData = Clone(data);
    var newDataItem = {};
    if (optionalEnforcedRowDataValues != null) {
      newDataItem = optionalEnforcedRowDataValues;
    } else {
      columnMetadataDefinitions.forEach((x) => {
        if (x.type === DynamicCellItemType.action) return;
        newDataItem[x.id] = x.defaultNewRowValue ?? null;
      });
    }
    setRowState(newDataItem, RowState.INSERTED);
    tableData.push(newDataItem);
    return tableData;
  },
};

export const RowState = {
  INSERTED: 1,
  UPDATED: 2,
  DELETED: -1,
  DEFAULT: 0,
};

function setRowState(row, rowState) {
  row.__rowState = rowState;
  row.__rowUpdated = Date.now();
}
