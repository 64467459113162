import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import ConsumerLayout from "layouts/Consumer.js";
import SignUpLayout from "layouts/SignUp.js";

import Login from "views/examples/Login.js";

function setToken(userToken) {
    localStorage.setItem('token', userToken);
}

function App() {
    const token = localStorage.getItem('token');

    if (!token) {
        return <Login setToken={setToken} />
    }

    return (
        <BrowserRouter>
        <Switch>
            <Route path="/auth" render={props => <Login {...props} />} />
            <Route path="/consumer" render={props => <ConsumerLayout {...props} />} />
            <Route path="/signup" render={props => <SignUpLayout {...props} />} />
            <Redirect from="/" to="/consumer/index"/>
        </Switch>
        </BrowserRouter>
    );
}

export default App;
