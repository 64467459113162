import React from "react";
// node.js library that concatenates classes (strings)
import { MDBDataTable } from "mdbreact";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { ConsumerHelper } from "../../../common/ConsumerHelper";

class ConsumerInstances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      instances: [],
    };
  }

  async componentDidMount() {
    const consumerInstancesResult =
      await ApiCallerHelper.Consumer.getConsumerInstances("current");
    this.setState({
      instances: consumerInstancesResult.data,
    });

    let instancesTemp = consumerInstancesResult.data;

    instancesTemp.forEach(function (part, index) {
      this[index].Buttons = (
        <React.Fragment>
          <Button
            color="primary"
            href={"/consumer/instance/" + this[index].Id}
            size="sm"
            title="Επεξεργασία"
          >
            Στοιχεία αίτησης
          </Button>
        </React.Fragment>
      );

      this[index].paratiriseis = (
        <React.Fragment>
          <ConsumerHelper.GetInstanceMessageToClient instance={part} />
        </React.Fragment>
      );
    }, instancesTemp);

    let dataForTable = {
      columns: [
        {
          label: "Α/Α ▼",
          field: "SerialNumber",
          sort: "asc",
          width: 100,
        },
        {
          label: "Κατασταση ▼",
          field: "Status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ενεργειες ▼",
          field: "Buttons",
          sort: "asc",
          width: 150,
        },
        {
          label: "Παρατηρησεις ▼",
          field: "paratiriseis",
          sort: "asc",
          width: 150,
        },
      ],
      rows: instancesTemp,
    };

    this.setState({
      instances: consumerInstancesResult.data,
      dummy: dataForTable,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Οι αιτήσεις μου</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="success"
                        href="/admin/newinstance"
                        size="sm"
                      >
                        Προσθήκη νέας αίτησης
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        data={this.state.dummy}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]}
                        entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={["Προηγούμενο", "Επόμενο"]}
                        infoLabel={[
                          "Εμφανίζονται",
                          "έως",
                          "από",
                          "καταχωρήσεις",
                        ]}
                        entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                      />
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardBody>
                  <p>
                    Σε περίπτωση επιθυμίας υπαναχώρησης, συμπληρώστε
                    <a href="https://deda.gr/xrisima-eggrafa/">αυτό</a> το
                    έγγραφο και στείλτε το στο <i>info@deda.gr</i>.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ConsumerInstances;
