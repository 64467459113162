import React from "react";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import CustomHeaderLabel from "../../views/custom/florina/CustomHeaderLabel"
import {ApiCallerHelper} from "../../api/apiCallerHelper";

class GenericHeader extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      gradientDirection: "right"      
    };
  };
  
  componentDidMount() {
    if (GlobalParametersHelper.getIsCustomFlorinaEnabled() == true){
      this.setState({
        gradientDirection : "left"
      });
    } 
    
    // const consumerInstancesResult = await ApiCallerHelper.Consumer.getConsumerInstances('current');
  };

  render() {
    return (
      <>

        <div className="header pb-8 pt-5 pt-md-8" style={{background: "linear-gradient(to " + this.state.gradientDirection + ", #00467F, #5CBC59)"}}>
          <Container fluid>
            <div className="header-body">
              { GlobalParametersHelper.getIsCustomFlorinaEnabled() == true ? <CustomHeaderLabel/> : <></>}   
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default GenericHeader;
