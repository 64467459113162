export const ConsumerHelper = {
  GetInstanceMessageToClient: ({ instance }) => {
    let errorMessage =
      "Εντοπίστηκε σφάλμα, ελέγξτε την πορεία της αίτησής σας.";
    let instancePending = "Η αίτησή σας βρίσκεται υπό επεξεργασία.";
    let instancePendingMechanic =
      "Απαιτείται ενέργεια από τον μηχανικό/εγκαταστάτη σας.";
    let instancePendingSupplier =
      "Απαιτείται ενέργεια από τον Πάροχο της επιλογής σας.";
    let instancePendingConsumerMessage = "Αναμένεται ενέργεια από μέρους σας.";
    let instanceStep11SubmittedPapers =
      "Η αίτησή σας είναι σε διαδικασία έκδοσης άδειας χρήσης";

    let isPaused = instance.Paused;
    let isDeclined = instance.Declined;
    let hasSubmittedFylloKafsis = instance?.hasSubmittedFylloKafsis ?? false;

    if (
      isPaused ||
      instance.Status == 3 ||
      instance.Status == 4 ||
      instance.Status == 5 ||
      instance.Status == 9
    ) {
      return (
        <>
          <i className="fas fa-pause text-gray" /> {instancePending}
        </>
      );
    }

    if (
      isPaused ||
      instance.Status == 6 ||
      instance.Status == 8 ||
      instance.Status == 10
    ) {
      return (
        <>
          <i className="fas fa-pause text-gray" /> {instancePendingMechanic}
        </>
      );
    }

    if (isPaused || instance.Status == 7) {
      return (
        <>
          <i className="fas fa-pause text-gray" /> {instancePendingSupplier}
        </>
      );
    }

    if (isDeclined) {
      return (
        <>
          <i className="fas fa-exclamation-triangle text-red" /> {errorMessage}
        </>
      );
    }

    // if (instance.Status == 11 && hasSubmittedFylloKafsis == true){
    if (instance.Status == 11) {
      return (
        <>
          <i className="fas fa-exclamation-triangle text-green" />
          {instanceStep11SubmittedPapers}
        </>
      );
    }

    return (
      <>
        <i className="fas fa-check text-green" />
        {instancePendingConsumerMessage}
      </>
    );
  },
};
export default ConsumerHelper;
