import React from "react";
import ReactDOM from "react-dom/client";
import App from "./views/App.js";

// import 'rsuite/dist/rsuite.css';
import 'assets/css/rsuite-default.css';
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
// import 'assets/css/rsuite-default.css';
import {EvoLoadingIndicator} from "./components/Abstract/EvoLoadingIndicator";
import {BaseContext} from "./contexts/BaseContext.js";
import {Version} from "./CopyrightVersion";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="tool-theme" data-version={Version.getVersion()} data-date_created={Version.getVersionDate()}>      
      <BaseContext>
        <App />
        <EvoLoadingIndicator />          
      </BaseContext>  
    </div>
  );
  