import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

import {
  getUserCurrentAsync
} from "./apiCallerHelper.auth"

import {
  getAllSuppliers,
  checkData,
  getSuppliersProfile,
  postSuppliersRequest,
  postSuppliersSavedata,
  postSuppliersSuccession,
} from "./apiCallerHelper.suppliers"

import {
  getExportConnections
} from "./apiCallerHelper.export"

import {
  getConsumerTypeByInstanceId,
  getConsumerInstances,
  getConsumerApplications,
  getConsumerRecentInstance
} from "./apiCallerHelper.consumer"

import {
  getWorkflowInstanceById,
  postUpdateWorkflowInstances,
  postSubmitWorkflowInstances,
} from "./apiCallerHelper.workflowInstances"

import {
  getFilesStatusByInstanceId
} from "./apiCallerHelper.files"

import {
  postConsumerApplicationToInstance
} from "./apiCallerHelper.applications"

export const ApiCallerHelper = {
  hasInitialisedToken: () => {
    return hasInitialisedToken();
  },
  Common: {
    get,
    post,
    delete: del,
  },
  Auth: {
    getUserCurrentAsync: getUserCurrentAsync,
  },
  Users: {
    getCurrentAsync: getUserCurrentAsync,
  },
  Suppliers: {
    getAllSuppliers: getAllSuppliers,
    checkData: checkData,
    getSuppliersProfile: getSuppliersProfile,
    postSuppliersRequest: postSuppliersRequest,
    postSuppliersSavedata: postSuppliersSavedata,
    postSuppliersSuccession: postSuppliersSuccession,
  },
  Export: {
    getExportConnections: getExportConnections,
  },
  WorkflowInstances: {
    getExportConnections: getExportConnections,
  },
  Consumer: {
    getConsumerTypeByInstanceId: getConsumerTypeByInstanceId,
    getConsumerInstances: getConsumerInstances,
    getConsumerApplications: getConsumerApplications,
    getConsumerRecentInstance: getConsumerRecentInstance
  },
  WorkflowInstances: {
    getWorkflowInstanceById: getWorkflowInstanceById,
    postUpdateWorkflowInstances: postUpdateWorkflowInstances,
    postSubmitWorkflowInstances: postSubmitWorkflowInstances,
  },
  Files: {
    getFilesStatusByInstanceId: getFilesStatusByInstanceId,
  },
  Applications : {
    postConsumerApplicationToInstance: postConsumerApplicationToInstance
  }
};


export default ApiCallerHelper;