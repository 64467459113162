/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import "@fortawesome/fontawesome-free/css/all.min.css";
import dedaLogo from "../../assets/img/brand/logo.jpg";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };

  createLinks = routes => {
    return (
      <>
      <NavItem>
        <NavLink
          to={'/consumer/index'}
          tag={NavLinkRRD}
          onClick={this.closeCollapse}
          activeClassName="active"
          style={{color: '#02497F'}}
        >
          <i className='fa fa-home text-primary mr-3'/>
          Αρχική
        </NavLink>
        <hr className="my-3" style={{color: '#02497F'}}/>
      </NavItem>

      <NavItem>
        <NavLink
          to={'/consumer/instances'}
          tag={NavLinkRRD}
          onClick={this.closeCollapse}
          style={{color: '#02497F'}}
        >          
          <i className="fa fa-stream text-primary mr-3" />
          Οι αιτήσεις μου
        </NavLink>
      </NavItem>

      

      <NavItem>
        <NavLink
          to={'/consumer/documents'}
          tag={NavLinkRRD}
          onClick={this.closeCollapse}
          style={{color: '#02497F'}}
        >
          <i className='fa fa-file text-primary mr-3'/>
          Τα έγγραφά μου
        </NavLink>
        <hr className="my-3" style={{color: '#02497F'}}/>
      </NavItem>

      <NavItem>
        <NavLink
          as="a" 
          href={GlobalParametersHelper.getIsCustomFlorinaEnabled() == true ? "https://deda.gr/wp-content/uploads/2023/03/Manual-CRM-_-Florina-final.pdf" : "https://deda.gr/wp-content/uploads/2021/11/myDEDA-manual.pdf" }
          target="_blank"
          style={{color: '#02497F'}}
        >
          <i className='fa fa-file text-primary mr-3'/>
          Οδηγίες Χρήσης
        </NavLink>
      </NavItem>
    </>
    );
  };

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light"
        expand="md"
        id="sidenav-main"
        style={{backgroundColor: "white"}}
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <img  
                style={{ width: '50%', margin: 'auto' }}
                alt='deda'
                src={dedaLogo}
              />
          {/* User */}
          <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        Λεωνίδας Παπαναστασίου  <i className="fa fa-caret-down" />
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/consumer/profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Προφίλ</span>
                  </DropdownItem>
                  <DropdownItem to="/consumer/settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Ρυθμίσεις</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/consumer/support" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Υποστήριξη</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="/auth/logout" /*onClick={localStorage.removeItem('token')}*/>
                    <i className="ni ni-button-power" />
                    <span>Αποσύνδεση</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3" />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
