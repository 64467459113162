import React, { Fragment, useEffect, useState } from "react";
export const PowerConsumptionValuesStatic = () => {
  return (
    <div className="mt-3">
      <h3>Υπερβάσεις Ανώτατου Χρηματικού Ποσού</h3>
      <hr></hr>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <table style={{ maxWidth: 900, textAlign: "center" }}>
          <thead style={{ border: 1 }}>
            <tr>
              <th style={{ minWidth: 100 }}>
                <b>Α/Α</b>
              </th>
              <th style={{ minWidth: 200 }}>
                <b>
                  Εγκατεστημένη ισχύς νέου συστήματος θέρμανσης λέβητα-καυστήρα
                  &nbsp;P (kW)
                </b>
              </th>
              <th style={{ minWidth: 300 }}>
                <b>Ανώτατος Συνολικός Επιλέξιμος Π/Υ (με ΦΠΑ) €</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>P≤35</p>
              </td>
              <td>
                <p>4.000&nbsp;€</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>2</p>
              </td>
              <td>
                <p>35&lt;P≤50</p>
              </td>
              <td>
                <p>6.500&nbsp;€</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>3</p>
              </td>
              <td>
                <p>50&lt;P≤100</p>
              </td>
              <td>
                <p>11.500&nbsp;€</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>4</p>
              </td>
              <td>
                <p>100&lt;P≤200</p>
              </td>
              <td>
                <p>16.000&nbsp;€</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>5</p>
              </td>
              <td>
                <p>P&gt;200</p>
              </td>
              <td>
                <p>21.500&nbsp;€</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PowerConsumptionValuesStatic;
