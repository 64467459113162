import { Steps, Panel, Notification, Timeline, Paragraph } from "rsuite";
import "assets/css/rsuite-default.css";
import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from "react-loadingg";
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

class ConsumerInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      comments: [],
      instance: [],
      workflow: [],
      ikasp: [],
      steps: [],
      showModal: false,
      user: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      const result = await axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/instance/" +
            handle +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          let step = 0;

          if (res.data.instance.Status <= 2) {
            step = 0;
          } else if (res.data.instance.Status <= 5) {
            step = 1;
          } else if (res.data.instance.Status <= 11) {
            step = 2;
          }
          if (res.data.instance.Status == 0 || res.data.instance.Status > 11) {
            step = 3;
          }

          this.setState({
            instance: res.data.instance,
            workflow: res.data.workflow,
            user: res.data.user,
            steps: res.data.stepsInfused,
            showModal: false,
            comments: [],
            step: step,
          });
        });

      const result1 = await axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/comments/" +
            handle +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({
            comments: res.data.comments,
            loading: false,
          });
        });

      fetch(
        process.env.REACT_APP_API_LINK + "/api/ikasp/instance/" + handle + "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              ikasp: result,
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <InstanceHeader />
          <div
            className="header pb-4"
            style={{
              background: "linear-gradient(to right, #00467F, #5CBC59)",
            }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Φυσικό Αέριο
                            </span>
                            <CardBody>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>Α/Α:</span>
                                &nbsp; {this.state.instance.SerialNumber}
                              </Row>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Ημερομηνία Έναρξης:
                                </span>
                                &nbsp;
                                {Moment(this.state.instance.StartedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </Row>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Κωδικός ΗΚΑΣΠ:
                                </span>
                                &nbsp; {this.state.ikasp.Ikasp}
                              </Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt--4" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Πορεία Αίτησης</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href={
                            "/consumer/instancedetails/" +
                            this.state.instance.Id
                          }
                          size="sm"
                        >
                          Τρέχον βήμα αίτησης
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Steps current={this.state.step}>
                        <Steps.Item
                          title="Αίτηση Σύνδεσης"
                          description="Συμπλήρωση, έλεγχος και έγκριση της αίτησής σας."
                        />
                        <Steps.Item
                          title="Συμβασιοποίηση"
                          description="Τεχνική αξιολόγηση ακινήτου και υπογραφή σύμβασης."
                        />
                        <Steps.Item
                          title="Κατασκευή Σύνδεσης"
                          description="Τεχνικές εργασίες κατασκευής και ενεργοποίησης της σύνδεσης."
                        />
                        <Steps.Item
                          title="Ολοκλήρωση της εγκατάστασης"
                          description="Είστε πλέον μέλος του δικτύου μας !"
                        />
                      </Steps>

                      {this.state.comments.map((dynamicData) => (
                        <>
                          {this.state.instance.Status ==
                          dynamicData.step.Status ? (
                            <>
                              <hr />
                              <Panel
                                header={`Στάδιο: ` + dynamicData.step.Name}
                              >
                                <Timeline>
                                  {dynamicData.actions.map((dynamicAction) => (
                                    <>
                                      {dynamicAction.comments.map(
                                        (dynamicComment) => (
                                          <>
                                            {dynamicComment.Canceled ? (
                                              <Timeline.Item>
                                                <span
                                                  style={{ color: "green" }}
                                                >
                                                  {Moment(
                                                    dynamicComment.CreatedAt
                                                  )
                                                    .locale("el")
                                                    .format(
                                                      "dddd, DD MMM YYYY HH:mm:ss"
                                                    )}
                                                  {dynamicAction.Name}: &nbsp;
                                                  {dynamicComment.Comment}
                                                </span>
                                              </Timeline.Item>
                                            ) : (
                                              <Timeline.Item>
                                                <span style={{ color: "red" }}>
                                                  {Moment(
                                                    dynamicComment.CreatedAt
                                                  )
                                                    .locale("el")
                                                    .format(
                                                      "dddd, DD MMM YYYY HH:mm:ss"
                                                    )}
                                                  {dynamicAction.Name}: &nbsp;
                                                  {dynamicComment.Comment}
                                                </span>
                                              </Timeline.Item>
                                            )}
                                          </>
                                        )
                                      )}
                                    </>
                                  ))}
                                </Timeline>
                              </Panel>
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default ConsumerInstance;
