import WorkflowInstancesHelper from "./workflowInstancesHelper";
export const ApplicationHelper = {
    GetApplicationAvailabilityDescription : (application) => {
        let isApplicationCustomFlorinaFlag = WorkflowInstancesHelper.IsApplicationCustomFlorinaFlag(application);
        let description = 'Εκτός Δικτύου';
        let isWithinAvailability = ((application?.availability??'') == 1)
        if (isWithinAvailability || 
            isApplicationCustomFlorinaFlag) {
            description = 'Εντός Δικτύου'
        }        
    }
}
export default ApplicationHelper