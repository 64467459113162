import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  
export async function postConsumerApplicationToInstance(  
  {
    Id,
    selectedClient,
    selectedUse,
    selectedFunding,
    selectedRole,
    Name,
    LastName,
    Email,
    phoneNumber,
    devices,
    consumption,
  }
) {
  let route = "/api/applications/toinstance/";
  let body =  {
    Id,
    selectedClient,
    selectedUse,
    selectedFunding,
    selectedRole,
    Name,
    LastName,
    Email,
    phoneNumber,
    devices,
    consumption,
  };
  return postAsync(route, body);
}

