import React, { useState } from "react";
import Select from "react-select";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import axios from "axios";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";
import WorkflowInstancesHelper from "../../../common/workflowInstancesHelper";
import EVOAlertComponent from "../../../components/Abstract/EvoAlertComponent";

import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";
import ApplicationHelper from "../../../common/ApplicationHelper";
import ApiCallerHelper from "../../../api/apiCallerHelper";
import RedirectHelper from "../../../common/redirectHelper";
const options_oikiakos = [
  { value: "1", label: "Ζεστό νερό" },
  { value: "2", label: "Θέρμανση χώρων" },
  { value: "3", label: "Κουζίνα" },
];

const options_emporikos = [
  { value: "1", label: "Φούρνος" },
  { value: "2", label: "Καθαριστήριο" },
  { value: "3", label: "Εστιατόριο" },
  { value: "4", label: "Καφέ-Μπαρ" },
  { value: "5", label: "Ξενοδοχείο" },
  { value: "6", label: "Κατάστημα" },
  { value: "7", label: "Γραφείο" },
  { value: "8", label: "Γυμναστήριο" },
  { value: "9", label: "Πρατήριο CNG" },
  { value: "10", label: "Άλλο" },
];

const options_dimosio_ktirio = [
  { value: "1", label: "Νοσοκομείο" },
  { value: "2", label: "Εκκλησία" },
  { value: "3", label: "Σχολείο" },
  { value: "4", label: "Κολυμβητήριο" },
  { value: "5", label: "Δημοτικό Γυμναστήριο" },
  { value: "6", label: "Άλλο" },
];

class CompleteApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      application: [],
      enabled: true,
      selectedClient: 0,
      selectedFunding: null,
      selectedUse: "",
      selectedRole: 0,
      submitButton: 0,
      name: "",
      last: "",
      email: "",
      phone: "",
      devices: "",
    };

    this.changeClient = this.changeClient.bind(this);
    this.changeUse = this.changeUse.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.changeFunding = this.changeFunding.bind(this);
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    const result = await axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/applications/check/" +
          handle +
          "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          application: res.data,
        });
      });
    //fetch city data
  }

  changeClient(event) {
    this.setState({ selectedClient: event.target.value });
    if (event.target.value == 4) {
      this.state.submitButton = 1;
    }
  }

  changeFunding(event) {
    this.setState({ selectedFunding: event.target.value });
  }

  changeUse = (e) => {
    let temp = "";
    for (let i = 0; i < e.length; i++) {
      temp += e[i].label + " ";
    }

    this.setState({ selectedUse: temp });
    this.state.selectedUse = temp;
  };

  changeRole(event) {
    this.setState({ selectedRole: event.target.value });

    if (event.target.value != 0) {
      this.state.submitButton = 1;
    }
  }

  updateName(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  updateLastName(evt) {
    this.setState({
      last: evt.target.value,
    });
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  updatePhone(evt) {
    this.setState({
      phone: evt.target.value,
    });
  }

  updateConsumption(evt) {
    this.setState({
      consumption: evt.target.value,
    });
  }

  updateDevices(evt) {
    this.setState({
      devices: evt.target.value,
    });
  }

  handleClick = async (stepStatus) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      if (this.state.selectedClient != 4) {
        let options = {
          Id: this.state.application.Id,
          selectedClient: this.state.selectedClient,
          selectedUse: this.state.selectedUse,
          selectedFunding: this.state.selectedFunding,
          selectedRole: this.state.selectedRole,
          Name: this.state.name,
          LastName: this.state.last,
          Email: this.state.email,
          phoneNumber: this.state.phone,
          devices: this.state.devices,
          consumption: this.state.consumption,
        };
        let postResult =
          await ApiCallerHelper.Applications.postConsumerApplicationToInstance(
            options
          );
        let getResult =
          await ApiCallerHelper.Consumer.getConsumerRecentInstance();
        let workflowInstanceId = getResult.data.Id;
        RedirectHelper.RedirectTo.consumerInstanceDetails(workflowInstanceId);

        // if (localStorage.getItem('token')) {
        //     fetch(process.env.REACT_APP_API_LINK + '/api/applications/toinstance/', {
        //         method: 'POST',
        //         headers: new Headers({
        //             'Authorization' : 'Bearer ' + localStorage.getItem('token'),
        //         }),
        //         body: JSON.stringify({
        //             'Id' : this.state.application.Id,
        //             'selectedClient' : this.state.selectedClient,
        //             'selectedUse' : this.state.selectedUse,
        //             'selectedFunding' : this.state.selectedFunding,
        //             'selectedRole' : this.state.selectedRole,
        //             'Name' : this.state.name,
        //             'LastName' : this.state.last,
        //             'Email' : this.state.email,
        //             'phoneNumber' : this.state.phone,
        //             'devices' : this.state.devices,
        //             'consumption' : this.state.consumption
        //         })
        //     })
        //     .then(data => {
        //         axios.get(process.env.REACT_APP_API_LINK + "/api/consumer/recent/instance/", {
        //             headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        //         })
        //         .then(res => {
        //
        //             setTimeout(function() {
        //                 window.location.replace("/consumer/instancedetails/" + res.data.Id);
        //             }, 2500);
        //         })
        //     })
        // }
      }
    }
  };

  isSubmitApplicationValid = () => {
    function getReturnObject(isValid, isContinueEnabled, message) {
      message = message || "";
      return {
        isValid: isValid,
        isContinueEnabled: isContinueEnabled,
        message: message,
        isValidAndContinue: isValid && isContinueEnabled,
      };
    }
    let hasAllFieldsCompleted = this.state.submitButton == 1;
    let selectedClient = this.state.selectedClient;
    let hasSelectedFunding = this.state.selectedFunding == 1;
    let hasNotSelectedFundingYet = (this.state.selectedFunding ?? 0) == 0;

    let isApplicationCustomFlorinaFlag =
      WorkflowInstancesHelper.IsApplicationCustomFlorinaFlag(
        this.state.application
      );

    let OIKIAKOS = 1;
    let notAllowedMessage =
      "Η κατηγορία πελάτη που επιλέξατε δεν είναι επιλέξιμη για χρηματοδότηση δαπανών εσωτερικής εγκατάστασης στο πλαίσιο της Δράσης “Αντικατάσταση συστημάτων θέρμανσης με συστήματα φυσικού αερίου στον Δήμο Φλώρινας” που θα υλοποιηθεί στο πλαίσιο του ΕΣΠΑ 2014-2020.";
    let allowedMessage =
      "Η κατηγορία πελάτη που επιλέξατε είναι επιλέξιμη για χρηματοδότηση δαπανών εσωτερικής εγκατάστασης στο πλαίσιο της Δράσης “Αντικατάσταση συστημάτων θέρμανσης με συστήματα φυσικού αερίου στον Δήμο Φλώρινας” που θα υλοποιηθεί στο πλαίσιο του ΕΣΠΑ 2014-2020.";
    let navigateToMyDeda =
      " Παρακαλούμε όπως μεταγείτε στην πλατφόρμα [my.deda.gr]";
    let navigateToFlorinaConsumer =
      " Παρακαλούμε όπως μεταγείτε στην πλατφόρμα [florina.deda.gr]";
    if (GlobalParametersHelper.getIsCustomFlorinaEnabled()) {
      if (!selectedClient) {
        return getReturnObject(false, "", "");
      }
      if (selectedClient == OIKIAKOS) {
        if (isApplicationCustomFlorinaFlag && hasNotSelectedFundingYet) {
          return getReturnObject(false, hasAllFieldsCompleted, "");
        } else if (isApplicationCustomFlorinaFlag && hasSelectedFunding) {
          return getReturnObject(true, hasAllFieldsCompleted, allowedMessage);
        } else {
          return getReturnObject(
            false,
            hasAllFieldsCompleted,
            notAllowedMessage + navigateToMyDeda
          );
        }
      }
      return getReturnObject(
        false,
        hasAllFieldsCompleted,
        notAllowedMessage + navigateToMyDeda
      );
    }
    if (selectedClient == OIKIAKOS && isApplicationCustomFlorinaFlag) {
      return getReturnObject(
        false,
        hasAllFieldsCompleted,
        allowedMessage + navigateToFlorinaConsumer
      );
    }
    return getReturnObject(true, hasAllFieldsCompleted);
  };

  RenderHelper = {
    IsCustomFlorina: () => {
      let flag =
        WorkflowInstancesHelper.IsApplicationAndSystemCustomFlorinaFlag(
          this.state.application
        );
      return flag;
    },
    FundingSelection: () => {
      let isFlorina =
        this.state.selectedClient == 1 && this.RenderHelper.IsCustomFlorina();
      if (isFlorina == false) {
        return;
      }

      return (
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="consumer-client">
                Αίτηση Χρηματοδότησης
              </label>

              <Input
                className="form-control-alternative"
                id="consumer-client"
                type="select"
                onChange={this.changeFunding}
              >
                <option value="0">-- Επιλέξτε --</option>
                <option value="1">
                  Ενδιαφέρομαι για χρηματοδότηση της αίτησής
                </option>
                <option value="2">
                  Δεν ενδιαφέρομαι για χρηματοδότηση της αίτησής
                </option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      );
    },
    FundingTopMessage: () => {
      let isSubmitApplicationValid = this.isSubmitApplicationValid();
      if (isSubmitApplicationValid.message != "") {
        let className =
          isSubmitApplicationValid.isValid == true
            ? "alert alert-success"
            : "alert alert-danger";
        return (
          <Row className="align-items-center">
            <div className="col">
              <div
                className={className}
                role="alert"
                style={{ display: "block" }}
              >
                {isSubmitApplicationValid.message}
              </div>
            </div>
          </Row>
        );
      }
      // if (WorkflowInstancesHelper.IsApplicationAndSystemCustomFlorinaFlag(this.state.application)){
      //     let notAllowedMessage = "Η κατηγορία πελάτη που επιλέξατε δεν είναι επιλέξιμη για χρηματοδότηση δαπανών εσωτερικής εγκατάστασης στο πλαίσιο της Δράσης “Αντικατάσταση συστημάτων θέρμανσης με συστήματα φυσικού αερίου στον Δήμο Φλώρινας” που θα υλοποιηθεί στο πλαίσιο του ΕΣΠΑ 2014-2020.";
      //     let allowedMessage = "Η κατηγορία πελάτη που επιλέξατε είναι επιλέξιμη για χρηματοδότηση δαπανών εσωτερικής εγκατάστασης στο πλαίσιο της Δράσης “Αντικατάσταση συστημάτων θέρμανσης με συστήματα φυσικού αερίου στον Δήμο Φλώρινας” που θα υλοποιηθεί στο πλαίσιο του ΕΣΠΑ 2014-2020."
      //     return (
      //         <Row className="align-items-center">
      //             <div className="col">
      //                 <div className={this.state.selectedClient > 1 || this.state.selectedFunding == 2 ? "alert alert-danger" : "alert alert-success"}  id={"showRequestNotif"} role="alert" style={{ display: 'block' }}>
      //                     {this.state.selectedClient > 1 || this.state.selectedFunding == 2 ?
      //                         <> {notAllowedMessage} </>
      //                         : <> {allowedMessage} </>
      //                     }
      //                 </div>
      //             </div>
      //         </Row>
      //     );
      // }
    },
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    }

    return (
      <>
        <InstanceHeader />
        <div
          className="header pb-4"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          ></CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Φυσικό Αέριο
                          </span>
                          <CardBody>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Διαθεσιμότητα Δικτύου:
                              </span>
                              {ApplicationHelper.GetApplicationAvailabilityDescription(
                                this.state.application
                              )}
                            </Row>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Ημερομηνία Υποβολής:
                              </span>
                              {Moment(this.state.application.dateCreated)
                                .locale("el")
                                .format("dddd, DD MMM YYYY")}
                            </Row>
                          </CardBody>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt-2" fluid>
          <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <this.RenderHelper.FundingTopMessage />
                {/* {this.RenderHelper.FundingTopMessage()} */}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">Προσδιορισμός Πελάτη</Col>
                    <Col className="text-right" xs="4">
                      {this.isSubmitApplicationValid().isValidAndContinue ? (
                        <Button
                          color="success"
                          onClick={() => {
                            this.handleClick();
                          }}
                          size="sm"
                        >
                          Συμπληρωματικά στοιχεία αίτησης
                        </Button>
                      ) : (
                        <p></p>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="consumer-client"
                            >
                              Κατηγορία Πελάτη
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="consumer-client"
                              type="select"
                              onChange={this.changeClient}
                            >
                              <option value="0">
                                -- Επιλέξτε κατηγορία πελάτη --
                              </option>
                              <option value="1">Οικιακός</option>
                              <option value="2">Εμπορικός</option>
                              <option value="3">Δημόσιο Κτίριο</option>
                              <option value="4">Βιομηχανικός Πελάτης</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <this.RenderHelper.FundingSelection />
                      {/* {this.RenderHelper.FundingSelection()} */}

                      {this.state.selectedClient == 4 ? (
                        <Row>
                          <Col lg="12">
                            <p style={{ fontSize: "20px" }}>
                              <i>
                                <strong>
                                  Κάποιος εκπρόσωπός μας θα έρθει σύντομα σε
                                  επικοινωνία μαζί σας !
                                </strong>
                              </i>
                            </p>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="consumer-user-home"
                                >
                                  Προβλεπόμενη χρήση* (Οικιακός)
                                </label>
                                <Select
                                  options={options_oikiakos}
                                  isDisabled={this.state.selectedClient != 1}
                                  onChange={this.changeUse}
                                  className="form-control-alternative"
                                  id="consumer-user-home"
                                  placeholder="Επιλογή..."
                                  isMulti
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="consumer-use-commercial"
                                >
                                  Προβλεπόμενη χρήση* (Εμπορικός)
                                </label>
                                <Select
                                  options={options_emporikos}
                                  isDisabled={this.state.selectedClient != 2}
                                  onChange={this.changeUse}
                                  className="form-control-alternative"
                                  id="consumer-user-home"
                                  placeholder="Επιλογή..."
                                  isMulti
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="consumer-use-public"
                                >
                                  Προβλεπόμενη χρήση* (Δημόσιο Κτίριο)
                                </label>
                                <Select
                                  options={options_dimosio_ktirio}
                                  isDisabled={this.state.selectedClient != 3}
                                  onChange={this.changeUse}
                                  className="form-control-alternative"
                                  id="consumer-user-home"
                                  placeholder="Επιλογή..."
                                  isMulti
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="align-items-center">
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Αριθμός Συσκευών
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id=""
                                  placeholder=""
                                  type=""
                                  disabled=""
                                  onChange={(evt) => this.updateDevices(evt)}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Συνολική Προβλεπόμενη Κατανάλωση (Kw/h)
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id=""
                                  placeholder=""
                                  type=""
                                  disabled=""
                                  onChange={(evt) =>
                                    this.updateConsumption(evt)
                                  }
                                />
                                <i>
                                  Η εγκατεστημένη ισχύς πρέπει να δηλώνεται
                                  αποκλειστικά σε kw
                                </i>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="consumer-role"
                                >
                                  Ιδιότητα Αιτούντος
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="consumer-role"
                                  type="select"
                                  onChange={this.changeRole}
                                  disabled={this.state.selectedUse == 0}
                                >
                                  <option value="0">
                                    -- Επιλέξτε ιδιότητα αιτούντος --
                                  </option>
                                  <option value="1">Ιδιοκτήτης</option>
                                  <option value="2">Ενοικιαστής</option>
                                  <option value="3">Διαχειριστής</option>
                                  <option value="4">
                                    Εξουσιοδοτημένος Εκπρόσωπος
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.state.selectedRole == 2 || this.state.selectedRole == 4 ? (
            <Row className="mt-5">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    Στοιχεία Ιδιοκτήτη Ακινήτου
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div className="pl-lg-4">
                        <Row className="align-items-center">
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όνομα Ιδιοκτήτη
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id=""
                                placeholder=""
                                type=""
                                disabled=""
                                onChange={(evt) => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Επίθετο Ιδιοκτήτη
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id=""
                                placeholder=""
                                type=""
                                disabled=""
                                onChange={(evt) => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Email Ιδιοκτήτη
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id=""
                                placeholder=""
                                type=""
                                disabled=""
                                onChange={(evt) => this.updateEmail(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Τηλέφωνο Ιδιοκτήτη
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id=""
                                placeholder=""
                                type=""
                                disabled=""
                                onChange={(evt) => this.updatePhone(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Col lg="12">
            <p>
              <strong>
                <i>*Σημείωση σχετικά με την προβλεπόμενη χρήση : </i>
              </strong>
            </p>
          </Col>

          <Col lg="12">
            <p>
              <i>Μπορείτε να επιλέξετε παραπάνω από μια προβλεπόμενη χρήση.</i>
            </p>
          </Col>
        </Container>
      </>
    );
  }
}

export default CompleteApplication;
