import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import {
  EVOReactTableDynamicGridHelper,
  GridActions,
} from "../../components/common/evo.react-table-dynamic-grid-helper";
import EvoActionsDynamicGridComponentTable from "./EvoActionsDynamicGridComponent.Table";
import { forwardRef, useImperativeHandle } from "react";
//import EVOAlertComponent from "../../components/common/EvoAlertComponent";

const EvoActionsDynamicGridComponent = forwardRef(
  (
    {
      className,
      dataset,
      columnMetadataDefinitions,
      height,
      width,
      scrollYOverflow,
      scrollXOverflow,
      innerHeight,
      innerWidth,
      innerScrollYOverflow,
      innerScrollXOverflow,
      isEditable,
      onUpdateCallback,
      onCustomCallbacks,
    },
    ref
  ) => {
    const [data, setData] = React.useState(() => dataset);
    //const [data, setData] = React.useState([])//() => dataset)
    const [deletedRows, setDeletedRows] = React.useState(() => []);
    const [originalData] = React.useState(data);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls onUpdate, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const onUpdate = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      setSkipPageReset(true);
      let updatedDataSource =
        GridActions.getUpdatedDataSourceAndColumnMetaDataDefinitions(
          data,
          rowIndex,
          columnId,
          value,
          columnMetadataDefinitions
        ).data;
      setData((dataSource) => updatedDataSource);
    };

    //call the onUpdateCallback to notify for new changes
    const _onUpdateCallback = (updatedDataSource) => {
      if (onUpdateCallback) {
        onUpdateCallback(updatedDataSource);
      }
    };

    const onDelete = (rowIndex, columnId) => {
      setSkipPageReset(true);

      var result = GridActions.getDatasourceAndDeletedItemByDeleteRowIndex(
        data,
        rowIndex
      );
      setData(result.data);

      deletedRows.push(result.deletedItem);
      setDeletedRows(deletedRows);
    };

    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
      setSkipPageReset(false);
      if (dataset != data) {
        _onUpdateCallback(data);
      }
      // if (isDisabled && isDisabled === true){
      //   columnMetadataDefinitions.forEach(element => {
      //     element.isEditable = false;
      //   });
      // }
    }, [data]);

    // Let's add a data resetter/randomizer to help
    // illustrate that flow...
    const resetData = () => setData(originalData);
    const logData = () => {
      console.log(data);
    };
    const addRow = (optionalEnforcedRowDataValues) => {
      setData(
        GridActions.getDatasourceWithNewRow(
          data,
          columnMetadataDefinitions,
          optionalEnforcedRowDataValues
        )
      );
    };

    const columns =
      EVOReactTableDynamicGridHelper.getReactTableColumnDefinition(
        columnMetadataDefinitions,
        isEditable
      );

    const onExportExcel = React.useRef(null);
    //PUBLICLY AVAILABLE FUNCTIONS WHEN USING REFERENCE OF GRID USING  const reactGridDynamicGridComponentRefs = React.createRef();
    useImperativeHandle(ref, () => ({
      onClickAddRow(optionalEnforcedRowDataValues) {
        addRow(optionalEnforcedRowDataValues);
      },
      onClickResetData: async () => {
        // let promptResult = await EVOAlertComponent.Prompt({});
        // if (promptResult.isConfirmed === true) {
        //   resetData();
        // }
      },
      getData() {
        return data;
      },
      onExportExcel(fileName, sheetName) {
        onExportExcel.current(fileName, sheetName);
      },
    }));
    return (
      <>
        <div
          className={className}
          style={{
            height: height ?? "",
            width: width ?? "",
            scrollYOverflow: scrollYOverflow ?? "",
            scrollXOverflow: scrollXOverflow ?? "",
          }}
        >
          {/* <button onClick={resetData}>Reset Data</button>
    <button onClick={logData}>logData</button>        
    <button onClick={addRow}>addRow</button>     */}
          <EvoActionsDynamicGridComponentTable
            columns={columns}
            columnMetadataDefinitions={columnMetadataDefinitions}
            data={data}
            onUpdate={onUpdate}
            onDelete={onDelete}
            skipPageReset={skipPageReset}
            height={innerHeight ?? ""}
            width={innerWidth ?? ""}
            scrollYOverflow={innerScrollYOverflow ?? ""}
            scrollXOverflow={innerScrollXOverflow ?? ""}
            onExportExcel={onExportExcel}
            onCustomCallbacks={onCustomCallbacks}
          />
        </div>
      </>
    );
  }
);

export default EvoActionsDynamicGridComponent;
