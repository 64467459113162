import imgDedaLogoMini from "../assets/img/brand/deda-logo-mini.png";
import imgDedaLogo from "../assets/img/brand/deda-logo.png";
import imglogo from "../assets/img/brand/logo.jpg";
import imgDedaBackground from "../assets/img/deda-background.jpg";
import imgEspaLogo from "../assets/img/ESPA_LOGO.jpg";

export const AssetHelper = {
  // Icons: {
  //     // edit: imgEdit,
  //     // delete: imgDelete,
  //     // add: imgAdd,
  //     // expand: imgExpand,
  //     // info: imgInfo,
  //     // sort: imgSort,
  //     // down: imgDown,
  //     // up: imgUp,
  //     // messages : imgMessages,
  // },
  Logo: {
    logoMini: imgDedaLogoMini,
    logoBrandLogo: imgDedaLogo,
    logo: imglogo,
  },
  Background: {
    primary: imgDedaBackground,
  },
  Assets: {
    Espa: {
      EspaLogo: imgEspaLogo,
    },
  },
};

export default AssetHelper;
