import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";

export const CustomHeaderLabel = ({}) => {
  if (GlobalParametersHelper.getIsCustomFlorinaEnabled() == false) {
    return;
  }

  return (
    <Card className="card-stats mb-2 " style={{ maxWidth: 350 }}>
      <CardBody>
        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
          Προγραμμα Χρηματοδοτησης Φλωρινας
        </CardTitle>
      </CardBody>
    </Card>
  );
};

export default CustomHeaderLabel;
