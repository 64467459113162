import React from 'react'
import styled from 'styled-components'
import { useTable, useSortBy, usePagination,  useFlexLayout, useResizeColumns } from 'react-table'
// import "bootstrap/dist/css/bootstrap.min.css";
import BTable from "react-bootstrap/Table";
import { EVOReactTableHelper } from "../../components/common/evo.react-table-helper";
import { EvoActionsDynamicGridComponentEditableCell } from "./EvoActionsDynamicGridComponent.EditableCell"
import { downloadExcel } from "react-export-table-to-excel";
// Be sure to pass our updateMyData and the skipPageReset option

import {useRef} from 'react';

const Styles = styled.div`
  input[type=number] {
    text-align:right;
  }

  input[type=text] {
    text-align:left;
  } 
`

const defaultColumn = {
  Cell: EvoActionsDynamicGridComponentEditableCell.GetDefaultEditableCell
}

function EvoActionsDynamicGridComponentTable({
  columns,
  columnMetadataDefinitions, 
  data, 
  onUpdate, 
  onDelete, 
  skipPageReset,
  pageCount: controlledPageCount,
  height,
  width,
  scrollYOverflow,
  scrollXOverflow,
  onExportExcel,
  onCustomCallbacks
  }) {    
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    footerGroups,
    rows,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      onUpdate,
      onDelete,
      columnMetadataDefinitions,      
      initialState: { 
        pageIndex: 0 , 
        pageSize : 10 ,
        hiddenColumns: columns
          .filter(col => col.show === false)
          .map(col => col.accessor)
      },
      onCustomCallbacks,
      // manualPagination: true,
      // pageCount: controlledPageCount,
    },    
    useResizeColumns,
    //useFlexLayout,
    useSortBy,
    usePagination
  )

  React.useEffect(()=>{
    onExportExcel.current = _onExportExcel;
  }, [])
  const tableRef = useRef(null);

  function _onExportExcel(fileName, sheetName) {
    fileName = fileName || "ExportedFile";
    sheetName = sheetName || "Sheet1";
    downloadExcel({      
      fileName: fileName,
      sheet: sheetName
    },tableRef.current);
  }

  // Render the UI for your table
  return (
    <Styles>  
      <div
       style={{ width: width, height: height,  overflowX: scrollXOverflow, overflowY: scrollYOverflow}}
      >
      <BTable striped bordered hover size="sm" {...getTableProps()} 
        // style={{ width: width, height: height,  overflowX: scrollXOverflow, overflowY: scrollYOverflow}} 
        ref={tableRef}       
        >      
        {/* <button onClick={resetData}>Reset Data</button> */}
        <thead>
          {EVOReactTableHelper.ReactTable.RenderHeader(headerGroups)}
        </thead>       
          <tbody {...getTableBodyProps()}>
            {EVOReactTableHelper.ReactTable.RenderDefaultBodyRows(
              page,
              prepareRow
            )}
          </tbody>
       
        <tfoot>
          {EVOReactTableHelper.ReactTable.RenderFooter(footerGroups)}
        </tfoot>        
      </BTable>     
      </div>
      {EVOReactTableHelper.ReactTable.RenderDefaultPagination(
        canPreviousPage,
        canNextPage,
        pageIndex,
        pageOptions,
        pageCount,
        pageSize,
        setPageSize,
        gotoPage,
        previousPage,
        nextPage
      )}
   </Styles>
  );
}

export default EvoActionsDynamicGridComponentTable