import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function getAllSuppliers() {
  var route = "/api/suppliers/all/";
  return getAsync(route);
}

export async function checkData(instanceId) {
  var route = "/api/suppliers/check_data/" + instanceId + "/";
  return getAsync(route);
}

export async function postSuppliersRequest({
  option,
  dateModal,
  reasonModal,
  SerialNumber,
}) {
  var route = "/api/suppliers/request/";
  let data = {
    option: option,
    dateModal: dateModal,
    reasonModal: reasonModal,
    SerialNumber: SerialNumber,
  };
  return postAsync(route, data);
}

export async function postSuppliersSavedata({
  serialNumber,
  name,
  lastName,
  phoneNumber,
  address,
  city,
  meterPower,
  afm,
}) {
  var route = "/api/suppliers/savedata/";
  let data = {
    SerialNumber: serialNumber,
    Name: name,
    LastName: lastName,
    phoneNumber: phoneNumber,
    Address: address,
    City: city,
    MeterPower: meterPower,
    afm: afm,
  };
  return postAsync(route, data);
}

export async function postSuppliersSuccession({
  serialNumber,
  name,
  lastName,
  phoneNumber,
  address,
  city,
  meterPower,
  afm,
}) {
  var route = "/api/suppliers/succession/";
  let data = {
    SerialNumber: serialNumber,
    Name: name,
    LastName: lastName,
    phoneNumber: phoneNumber,
    Address: address,
    City: city,
    MeterPower: meterPower,
    afm: afm,
  };
  return postAsync(route, data);
}

export async function getSuppliersProfile(profileId) {
  var route = "/api/suppliers/profile/" + profileId + "/";
  return getAsync(route);
}
