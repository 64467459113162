export const RedirectHelper = {
    RedirectTo: {
        reloadPage : () => { window.location.reload();},
        reloadPageFromCache : () => { window.location.reload(false);},
        consumer: () => _location("/consumer/index"),
        consumerInstanceDetails: (instanceId) => _location("/consumer/instancedetails/" + instanceId),
        // home: () => _location("/basicLayout/index/"),
        // login: () => _location("/auth/login"),
        // adminDetails: (workflowInstanceId) => _location("/admin/details/" + workflowInstanceId),
        // flowInstance: (self, workflowInstanceId) => _locationRedirectReact(self, "/basicLayout/instance/" + workflowInstanceId),
        // dynamicRedirect: (url) => _location(url),
        details: (self, workflowInstanceId) => _locationRedirectReact(self, '/basicLayout/details/' + workflowInstanceId),        
        // passwordChange: () => _location("/passwordchange/"),        
        // monthlyPrint: () => _location("/printLayout/MonthlyPrint/"),        
      },
    };
    
    function _locationRedirectReact(self, route) {
      self.props.history.push(route)
    }
    
    function _location(route) {
      window.location.replace(route);
    }    

export default RedirectHelper;