import React, { Fragment, useEffect, useState } from "react";
import EvoInput from "../../../../components/common/evo.Input";
import { Row, Col, Container } from "reactstrap";
import { Styles } from "./TechnicalDescription.style";
import EvoActionsDynamicGridComponent from "../../../../components/DynamicGridComponent/EvoActionsDynamicGridComponent";
import MaxPowerConsumptionRate from "./MaxPowerConsumptionRate"
import {
  GetTechnicalDescriptionColumnDefinitions,  
  GetTechnicalDescriptionIsValidForSubmit,
  GetDefaultViewModel,
  TechnicalDescriptionActionTypeEnum,
  TechnicalDescriptionActionIdEnum,
  TechnicalDescriptionCalculations
} from "./TechnicalDescription.Helper";

import MaxPowerConsumptionRateHelper from "./MaxPowerConsumptionRate.Helper"
export const TechnicalDescription = ({  
  title,
  actionType,
  actionId,
  stepId,
  actionDataValue,
  isReadOnly,
  onUpdateCallback,  
  steps //used to pass a reference of all steps within a give instance
}) => {           
  const reactGridDynamicGridComponentRefs = React.createRef();
  const [viewModel, setViewModel] = useState(null); 
  const [reactRenderFlag, setReactRenderFlag] = useState(null); 

  
  const getIsConsumerActionId = () => {
    return (actionId == TechnicalDescriptionActionIdEnum.ConsumerActionId);    
  }

  let literals =  {
         title: title,
         description:"Έντυπο Οικονομικής Προσφοράς για το κόστος της εσωτερικής εγκατάστασης φυσικού αερίου",
         pipeDiameter : "Σωληνώσεις ΦΑ m",
         boilerBrand : "Λέβητας Μάρκα",
         boilerModel : "Λέβητας Μοντέλο",
         defaultPlaceholder : "Παρακαλώ Συμπληρώστε"
       }

  const setActionDataValue = (actionDataValue) => {
    if (isActionTypeVisible() == false) {
      return;
    }  
    if (!actionDataValue) {
      return;
    }
    let data = JSON.parse(actionDataValue);
    let newViewModel = { ...viewModel, ...data };
    setViewModel(newViewModel);
  };    

  useEffect(()=> {    
    if (viewModel != null) {return;}    
    setViewModel(GetDefaultViewModel())            
  })  
  
  useEffect(() => {    
    if (isActionTypeVisible() == false) {
      return;
    }    
    setActionDataValue(actionDataValue);
  }, [actionDataValue]);
   
  const isActionTypeVisible = () => {   
    let isCorrectActionType = (actionType == TechnicalDescriptionActionTypeEnum.DynamicGridTechnicalDescription);
    return isCorrectActionType;
  }

  if (!isActionTypeVisible()) {
    return;
  }    

  //Notify changes when values chage by calling the onUpdateCallback
  const notifyChanges = () => {           
    setReactRenderFlag(Date.now()); //hack      

    if (!onUpdateCallback){return;}        
    let componentCalculatedValues = getComponentCalculatedValuesViewModel()
    onUpdateCallback(componentCalculatedValues);
  }

  /**
   * gets the current calculated values of he component as a result from input given on the Technical Description grid
  */ 
  const getComponentCalculatedValuesViewModel = () => {
    let componentCalculatedValues = TechnicalDescriptionCalculations.getTechnicalDescriptionResultViewModel(
      actionType, actionId, stepId,
      viewModel.technicalDescriptionDatasource,
      viewModel.pipeDiameter,
      viewModel.boilerBrand,
      viewModel.boilerModel, 
      MaxPowerConsumptionRateHelper.getStepActionConsumptionRateData(steps)
    );
    componentCalculatedValues.isValidForSubmit = GetTechnicalDescriptionIsValidForSubmit(viewModel);
    return componentCalculatedValues;
  }

     /**
   * Renders the component that shows a notification of the total ammount (if it exceeds or not a value)
   * 
   * @param {String} actionId - the action id (if it is the consumer or non consumer, (step 12 action id)).
   * @param {Object} viewModel - the view model that contains all the changes of the grid
   * 
   */  
     const NotificationComponent1to3 = ({actionId, viewModel}) => {
      let MAX_SUM_AMOUNT_Q1_TO_Q3 = 500;
      let literals =  {
        getNotification : (sumAmount13) => { return 'Το άθροισμα των δαπανών 1 έως και 3 δεν μπορεί να υπερβαίνει το ποσό των ' + MAX_SUM_AMOUNT_Q1_TO_Q3 + ' €. Η επιπλέον δαπάνη θα καλύπτεται με ίδια συμμετοχή.' }
      }
  
      //checks whether the actionId was the consumer action id or the last step (12) action id, if so, then it means it is the edited sums
      // by a non consumer so we must pass it as an argument
      let isEditedMode = getIsConsumerActionId() == false;        
      let question1to3SumAmount = TechnicalDescriptionCalculations.getQuestion1to3SumAmount(
        viewModel.technicalDescriptionDatasource,
        isEditedMode      
      );      
  
      let isSkipRender = question1to3SumAmount <= MAX_SUM_AMOUNT_Q1_TO_Q3;
      if ( isSkipRender ) {return;}    
      return (
        <Fragment>
          <div className="p-3 mb-2 bg-warning text-white">
            <Row>
              <Col md="12">{literals.getNotification(question1to3SumAmount)}</Col>                                
            </Row>
          </div>        
        </Fragment>
      );    
    }

   /**
   * Renders the component that shows a notification of the total ammount (if it exceeds or not a value)
   * 
   * @param {String} actionId - the action id (if it is the consumer or non consumer, (step 12 action id)).
   * @param {Object} viewModel - the view model that contains all the changes of the grid
   * 
   */  
  const NotificationComponent1to5 = ({actionId, viewModel}) => {
    let literals =  {
      getNotification : (sumAmount15, exceedingAmount) => { return 'Το ποσό για τις εγγραφές 1~5 [' + sumAmount15 + '] υπερβαίνει το 35% του Τελικού Συνολικού Ποσού με Φ.Π.Α. Πλεονάζων ποσό : [' + exceedingAmount +']' }
    }

    //checks whether the actionId was the consumer action id or the last step (12) action id, if so, then it means it is the edited sums
    // by a non consumer so we must pass it as an argument
    let isEditedMode = getIsConsumerActionId() == false;        
    let question1to5ExcessiveSumAmount = TechnicalDescriptionCalculations.getQuestion1to5ExcessiveSumAmount(
      viewModel.technicalDescriptionDatasource,
      isEditedMode      
    );

    let question1to5SumAmount = TechnicalDescriptionCalculations.getTechnicalDescriptionSumPrice(
      viewModel.technicalDescriptionDatasource,
      isEditedMode,
      true      
    );

    let isSkipRender = question1to5ExcessiveSumAmount <= 0;
    if ( isSkipRender ) {return;}    
    return (
      <Fragment>
        <div className="p-3 mb-2 bg-warning text-white">
          <Row>
            <Col md="12">{literals.getNotification(question1to5SumAmount, question1to5ExcessiveSumAmount)}</Col>                                
          </Row>
        </div>        
      </Fragment>
    );    
  }
 
  let isSkipRender = 
    isActionTypeVisible() == false || 
    viewModel == null;
  
  if (isSkipRender) {return;}
  
  return (
    <Fragment>
      <Container fluid>
      <label
          className="form-control-label"
          htmlFor="input-username"
      >
          {literals.title}
      </label>     
      <Row>        
        <Col lg="12">
          <p>{literals.description}</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="4">
          {literals.pipeDiameter} :
          <EvoInput.Input            
            defaultValue={viewModel.pipeDiameter}
            placeholder={literals.pipeDiameter}
            onChange={(e) => {
              let value = e.target.value;
              viewModel.pipeDiameter = value;     
              setViewModel(viewModel);
              notifyChanges();
            }}
            disabled = {isReadOnly}
          />
        </Col>
        <Col md="4">
          {literals.boilerBrand} :
          <EvoInput.Input            
            defaultValue={viewModel.boilerBrand}
            placeholder={literals.boilerBrand}
            onChange={(e) => {
              let value = e.target.value;
              viewModel.boilerBrand = value;
              notifyChanges();
            }}
            disabled = {isReadOnly}
          />
        </Col>
        <Col md="4">
          {literals.boilerModel} :
          <EvoInput.Input            
            defaultValue={viewModel.boilerModel}
            placeholder={literals.boilerModel}
            onChange={(e) => {
              let value = e.target.value;
              viewModel.boilerModel = value;
              notifyChanges();
            }}
            disabled = {isReadOnly}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg="12">
          <Styles>
            <EvoActionsDynamicGridComponent
              className={"tool-theme--react-table"}
              dataset={viewModel.technicalDescriptionDatasource}
              ref={reactGridDynamicGridComponentRefs}
              columnMetadataDefinitions={GetTechnicalDescriptionColumnDefinitions(actionId)}
              innerScrollXOverflow={"auto"}
              isEditable={(isReadOnly??false) == false}
              onUpdateCallback={(data) => {
                viewModel.technicalDescriptionDatasource = data;
                notifyChanges();
              }}
            ></EvoActionsDynamicGridComponent>
          </Styles>
        </Col>
      </Row>
      <NotificationComponent1to5
        actionId={actionId}
        viewModel = {viewModel}
      />   
      <NotificationComponent1to3
        actionId={actionId}
        viewModel = {viewModel}
      />            
      <MaxPowerConsumptionRate                        
        componentCalculatedValuesViewModel = {getComponentCalculatedValuesViewModel()}        
      />      
      </Container>
    </Fragment>
  );
};

export default TechnicalDescription;
