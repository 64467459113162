export function Clone(object) {
    return JSON.parse(JSON.stringify(object));
}

export function GetObjectMapWithSameProperties(objectSource, objectTarget) {
    const result = {};
    Object.keys(objectSource).forEach((key) => {
      if (key in objectTarget) {
        result[key] = objectTarget[key];
      }
    });
    return result; 
  }

export function GetEnumsIntoKeyValue(constantEnum, descriptionFieldName) {
    var result = [];
    for (const key in constantEnum) {
        var item = { value: key };
        item[descriptionFieldName] = constantEnum[key];
        result.push(item);
    }
    return result;
}

export function Sort(array, fieldName, direction) {
    direction = direction || 1;
    return array.sort((a, b) => (a[fieldName] ?? '' > b[fieldName] ?? '') ? direction : direction * -1);
}

export function getBoolean(value) {
    if (!value) {
        return false;
    }
    if (typeof value === 'boolean') {
        return value;
    } 
    if (value == 1 ||
        value.toString().toLowerCase().trim() == '1' ||
        value.toString().toLowerCase().trim() == 'true' ||
        value.toString().toLowerCase().trim() == 'yes'
    ) {
        return true;
    }

    return false;
}

export function isNullOrEmptyString(value){
    return (!value || value.trim().length === 0)
}