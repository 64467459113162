import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import {Oval} from 'react-loader-spinner';
import { Styles } from "./EvoLoadingIndicator.styles";


export const EvoLoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (   
      <Styles>  
        <div className="tool-loader--container">
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          wrapperStyle={{                     
          }}
          wrapperClass="tool-loader--spinner"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#4fa94d"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />              
        </div>  
      </Styles>   
    )
  );
};