import {GetEnumsIntoKeyValue} from "../../common/utils"

export const DynamicInputValidatonType = {
    NONE : 'Χωρίς Έλεγχο',
    //EMAIL : 'Email',    
    //VAT_NUMBER_GR : 'ΑΦΜ',
    INTEGER_ONLY : 'Αριθμός χωρίς δεκαδικά',    
    DECIMAL : 'Αριθμός με δεκαδικά',
    ALPHABET : 'Γράμματα Μόνο',
    ALPHABET_SYMBOLS : 'Γράμματα και σύμβολα'
}	

export function GetDynamicInputValidatonTypeOptionList() {
    return GetEnumsIntoKeyValue(DynamicInputValidatonType, "label");    
}