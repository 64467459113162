import EVOAlertComponent from "../../../../components/Abstract/EvoAlertComponent";
import GlobalParametersHelper from "../../../../common/GlobalParametersHelper";
import MaxPowerConsumptionRateHelper from "./MaxPowerConsumptionRate.Helper";

const IS_CUSTOM_FLORINA_ENABLED =
  GlobalParametersHelper.getIsCustomFlorinaEnabled
    ? GlobalParametersHelper.getIsCustomFlorinaEnabled()
    : false;
const COL_VAT_VALUE = 24;

export const TechnicalDescriptionActionIdEnum = {
  ConsumerActionId: "563e0d2b-5b26-47e9-9e84-1b1ad6bdad04",
  CRMActionId: "fd7c4589-ecb8-4bba-86bc-0e5bb70acd58",
};

export const CustomStepsIdEnum = {
  step_12_dynamic_grid_technical_description:
    "bfaf78ba-c0ff-4454-8502-2eb5c79b2cdb", //"Πιστοποίηση της επιχορηγούμενης εσωτερικής εγκατάστασης φυσικού αερίου και Καταβολή της Επιχορήγησης"
};

export const TechnicalDescriptionActionTypeEnum = {
  DynamicGridTechnicalDescription: "dynamic_grid_technical_description",
};

let headerStyleDefault = {
  textTransform: "none",
  fontWeight: "bold",
  fontSize: "small",
};

let footerStyleDefault = {
  textAlign: "right",
  textTransform: "none",
  fontWeight: "bold",
  fontSize: "small",
};

//UPDATES STEP DATA in parent control and if so it returns true
export const SetTechnicalDescriptionStepActionData = (e, steps) => {
  if (
    (e.stepActionType ?? "") !=
    TechnicalDescriptionActionTypeEnum.DynamicGridTechnicalDescription
  ) {
    return false;
  }
  let stepId = e.stepId;
  let actionId = e.actionId;
  let action =
    steps
      .find((x) => x.step.Id == stepId)
      ?.actions?.find((x) => x.action.Id == actionId) ?? null;
  if (action) {
    action.data.Data = e.value;
    return true;
  }
  return false;
};

export const GetTechnicalDescriptionStepActionIsValidForSubmit = (
  steps,
  stepStatus,
  actionId
) => {
  if (IS_CUSTOM_FLORINA_ENABLED == false) {
    return true;
  }
  let stepId = steps[stepStatus - 1].step.Id;
  let action =
    steps
      .find((x) => x.step.Id == stepId)
      ?.actions?.find((x) => x.action.Id == actionId) ?? null;
  if (!action) {
    return true;
  }

  let isValidForSubmit = false;

  try {
    let data = action.data.Data;
    let dataObj = JSON.parse(data);
    isValidForSubmit = GetTechnicalDescriptionIsValidForSubmit(dataObj);
  } catch (ex) {
    console.error(ex);
  }

  if (isValidForSubmit == false) {
    EVOAlertComponent.Alert({
      message: "Παρακαλούμε πολύ συμπληρώστε τα πεδία του πίνακα.",
    });
    return false;
  }

  return true;
};

//returns the default view model of the control
export const GetDefaultViewModel = () => {
  let defaultViewModel = {
    technicalDescriptionDatasource: GetTechnicalDescriptionInitialDatasource(),
    pipeDiameter: null,
    boilerBrand: null,
    boilerModel: null,
  };
  return defaultViewModel;
};

export const GetTechnicalDescriptionIsValidForSubmit = (viewModel) => {
  function chekFieldIsNotEmptyOrNull(data, field, key) {
    if (!data[field] && String(data[field]) != "0") {
      return false;
    }
  }

  //Validation Conditions
  function isAdditionalFieldsValid(viewModel) {
    let isValid = true;
    ["pipeDiameter", "boilerBrand", "boilerModel"].forEach((x) => {
      if (
        chekFieldIsNotEmptyOrNull(viewModel, x, "getIsValidForSubmit") == false
      ) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  function isTechnicalDescriptionValid(viewModel) {
    let isValid = true;
    let rows = viewModel.technicalDescriptionDatasource;
    rows.every((row) => {
      let isRowValid = true;
      ["colPrice", "colVat", "colPriceSum"].every((x) => {
        if (
          chekFieldIsNotEmptyOrNull(
            row,
            x,
            "isTechnicalDescriptionValid " + row.colId
          ) == false
        ) {
          isRowValid = false;
          return false;
        }
      });
      if (!isRowValid) {
        isValid = false;
      }
      return false;
    });
    return isValid;
  }

  //Check validation conditions
  if (
    viewModel == null ||
    isAdditionalFieldsValid(viewModel) == false ||
    isTechnicalDescriptionValid(viewModel) == false
  ) {
    return false;
  }

  return true;
};

export const GetTechnicalDescriptionColumnDefinitions = (
  technicalDescriptionActionTypeEnum
) => {
  let technicalDescriptionColumnDefinitions = [
    {
      id: "colId",
      title: "α/α",
      type: "text",
      isEditable: false,
      width: 50,
      headerStyle: headerStyleDefault,
      style: { textAlign: "center", maxWidth: 100 },
    },
    {
      id: "colTitle",
      title: "Τεχνική περιγραφή (π.χ. ισχύς μέτρα)",
      type: "text",
      isEditable: false,
      headerStyle: headerStyleDefault,
      style: { textAlign: "left" },
    },
    {
      id: "colPrice",
      title: "Καθαρό ποσό(€)",
      type: "number",
      isEditable: true,
      width: 100,
      footerCalculationType: "sum",
      onAfterChangeValueRecalculateColumns: [
        "colPriceSum",
        "colPriceEdited",
        "colPriceSumEdited",
      ],
      headerStyle: {
        ...headerStyleDefault,
        wordWrap: "normal",
        whiteSpace: "normal",
        maxWidth: 120,
      },
      footerStyle: footerStyleDefault,
      style: { textAlign: "right" },
      defaultNewRowValue: 0,
      onAfterChangeValue: (e) => {
        let colPrice = e.row.colPrice;
        if (!colPrice && String(colPrice) != "0") {
          e.row.colPrice = 0;
        }
      },
    },
    {
      id: "colVat",
      title: "ΦΠΑ(€)",
      type: "number",
      isEditable: false,
      width: 100,
      headerStyle: {
        ...headerStyleDefault,
        wordWrap: "normal",
        whiteSpace: "normal",
        maxWidth: 100,
      },
      style: { textAlign: "center" },
    },
    {
      id: "colPriceSum",
      title: "Τελική τιμή με ΦΠΑ(€)",
      type: "number",
      isEditable: false,
      width: 100,
      footerCalculationType: "sum",
      headerStyle: {
        ...headerStyleDefault,
        wordWrap: "normal",
        whiteSpace: "normal",
        maxWidth: 100,
      },
      footerStyle: footerStyleDefault,
      style: { textAlign: "right" },
      onCalculateValue: (e) => {
        let colPrice = e.row.colPrice;
        e.row.colPriceSum = 0;
        if (colPrice && colPrice != 0) {
          let value = colPrice * (COL_VAT_VALUE / 100 + 1);
          value = Number(value.toFixed(2));
          e.row.colPriceSum = value;
        }
      },
    },

    {
      id: "colPriceEdited",
      title: "Καθαρό ποσό(€)",
      type: "number",
      isEditable: true,
      width: 100,
      footerCalculationType: "sum",
      onAfterChangeValueRecalculateColumns: ["colPriceSumEdited"],
      headerStyle: {
        ...headerStyleDefault,
        wordWrap: "normal",
        whiteSpace: "normal",
        maxWidth: 100,
        backgroundColor: "cyan",
      },
      footerStyle: footerStyleDefault,
      style: { textAlign: "right" },
      defaultNewRowValue: 0,
      onCalculateValue: (e) => {
        e.row.colPriceEdited = e.row.colPrice;
      },
      onAfterChangeValue: (e) => {
        let colPriceEdited = e.row.colPriceEdited;
        if (!colPriceEdited && String(colPriceEdited) != "0") {
          e.row.colPriceEdited = 0;
        }
      },
      show: false,
    },

    {
      id: "colPriceSumEdited",
      title: "Τελική τιμή με ΦΠΑ(€)",
      type: "number",
      isEditable: false,
      width: 100,
      footerCalculationType: "sum",
      headerStyle: {
        ...headerStyleDefault,
        wordWrap: "normal",
        whiteSpace: "normal",
        maxWidth: 100,
        backgroundColor: "cyan",
      },
      footerStyle: footerStyleDefault,
      style: { textAlign: "right" },
      onCalculateValue: (e) => {
        let colPriceEdited = e.row.colPriceEdited;
        e.row.colPriceSumEdited = 0;
        if (colPriceEdited && colPriceEdited != 0) {
          let value = colPriceEdited * (COL_VAT_VALUE / 100 + 1);
          value = Number(value.toFixed(2));
          e.row.colPriceSumEdited = value;
        }
      },
      show: false,
    },
  ];

  if (
    technicalDescriptionActionTypeEnum ==
    TechnicalDescriptionActionIdEnum.ConsumerActionId
  ) {
    return technicalDescriptionColumnDefinitions;
  }

  if (
    technicalDescriptionActionTypeEnum ==
    TechnicalDescriptionActionIdEnum.CRMActionId
  ) {
    let colPrice = technicalDescriptionColumnDefinitions.find(
      (x) => x.id == "colPrice"
    );
    colPrice.isEditable = false;
    colPrice.headerStyle = {
      ...colPrice.headerStyle,
      backgroundColor: "orange",
    };

    let colPriceSum = technicalDescriptionColumnDefinitions.find(
      (x) => x.id == "colPriceSum"
    );
    colPriceSum.headerStyle = {
      ...colPrice.headerStyle,
      backgroundColor: "orange",
    };

    let colPriceEdited = technicalDescriptionColumnDefinitions.find(
      (x) => x.id == "colPriceEdited"
    );
    colPriceEdited.show = true;
    let colPriceSumEdited = technicalDescriptionColumnDefinitions.find(
      (x) => x.id == "colPriceSumEdited"
    );
    colPriceSumEdited.show = true;

    return technicalDescriptionColumnDefinitions;
  }

  return [];
};

export const GetTechnicalDescriptionInitialDatasource = () => {
  let initialDatasource = [
    {
      colId: "1",
      colTitle:
        "Εκπόνηση Μελέτης και επίβλεψης κατασκευής εσωτερικής εγκατάστασης",
    },
    {
      colId: "2.a",
      colTitle:
        "Ενεργειακή Επιθεώρηση υφιστάμενου συστήματος θέρμανσης πετρελαίου",
    },
    {
      colId: "2.b",
      colTitle:
        "Έκδοση Φύλλου ανάλυσης καύσης υφιστάμενου συστήματος θέρμανσης πετρελαίου( σε περίπτωση που πραγματοποιείται νέα μέτρηση στο πλαίσιο της δράσης)",
    },
    {
      colId: "3",
      colTitle:
        "Εργασίες για την έναυση καυστήρα φυσικού αερίου και έκδοση Φύλλου ανάλυσης καύσης του συστήματος θέρμανσης φυσικού αερίου",
    },
    {
      colId: "4",
      colTitle:
        "Εργασίες εγκατάστασης σωληνώσεων, καμινάδας και λοιπές εργασίες λεβητοστασίου ",
    },
    {
      colId: "5",
      colTitle:
        "Προμήθεια και τοποθέτηση ηλεκτροβανών και ανιχνευτών και λοιπών υλικών σχετικών με τον αερισμό και την απαγωγή καυσαερίων",
    },
    {
      colId: "6",
      colTitle: "Σωληνώσεις ΦΑ(m) και καμινάδα",
    },
    {
      colId: "7",
      colTitle: "Προμήθεια και εγκατάσταση Λέβητα",
    },
    {
      colId: "8",
      colTitle:
        "Εργασίες και υλικά υδραυλικής σύνδεσης του συστήματος θέρμανσης και υλικά μετατροπής των υδραυλικών δικτύων του διαμερίσματος στις αυτόνομες θερμάνσεις",
    },
  ];

  initialDatasource.forEach((x) => {
    x.colPrice = 0;
    x.colVat = COL_VAT_VALUE;
    x.colPriceSum = 0;
  });

  return initialDatasource;
};

export const TechnicalDescriptionCalculations = {
  _isColIds1to3ItemId: (id) => {
    let colIds1to3 = ["1", "2.a", "2.b", "3"];
    let hasItem = colIds1to3.indexOf(id) > -1;
    return hasItem;
  },
  _isColIds1to5ItemId: (id) => {
    let colIds1to5 = ["1", "2.a", "2.b", "3", "4", "5"];
    let hasItem = colIds1to5.indexOf(id) > -1;
    return hasItem;
  },
  /**
   * Calculates the sum of prices in technical description based on the provided conditions.
   *
   * @param {Array} technicalDescriptionDatasource - An array of objects that contains the technical description data.
   * @param {Boolean} isEdited - A flag indicating whether the price is edited or not by a non consumer.
   * @param {Boolean} isColIds1to5 - A flag indicating whether to only consider prices that are between 1 to 5 items.
   *
   * @returns {Number} The sum of prices in technical description based on the provided conditions.
   */
  getTechnicalDescriptionSumPrice: (
    technicalDescriptionDatasource,
    isEdited,
    isColIds1to5
  ) => {
    let result = 0;
    try {
      let isColIds1to5ItemId =
        TechnicalDescriptionCalculations._isColIds1to5ItemId;

      technicalDescriptionDatasource.forEach((x) => {
        let price = x.colPriceSum;
        if (isEdited == true) {
          price = x.colPriceSumEdited;
        }

        let shouldAddPrice =
          !isColIds1to5 || (isColIds1to5 && isColIds1to5ItemId(x.colId));
        if (shouldAddPrice) {
          result = result + price;
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      if (isNaN(result)) {
        result = 0;
      }
    }
    result = Number(result.toFixed(2));
    return result;
  },
  /**
   * Calculates the excessive Amount of questions 1 to 5 (positive if excessive amount found above a specific rate)
   *
   * @param {Array} technicalDescriptionDatasource - An array of objects that contains the technical description data.
   * @param {Boolean} isEdited - A flag indicating whether the price is edited or not by a non consumer.
   *
   * @returns {Number} The sum of prices in technical description based on the provided conditions.
   */
  getQuestion1to5ExcessiveSumAmount: (
    technicalDescriptionDatasource,
    isEdited
  ) => {
    let result = 0;
    try {
      let getTechnicalDescriptionSumPrice =
        TechnicalDescriptionCalculations.getTechnicalDescriptionSumPrice;
      const PERCENTAGE = 0.35;
      let currentAmount1to5 = getTechnicalDescriptionSumPrice(
        technicalDescriptionDatasource,
        isEdited,
        true
      );
      let currentAmount = getTechnicalDescriptionSumPrice(
        technicalDescriptionDatasource,
        isEdited,
        false
      );
      let percentAmount = currentAmount * PERCENTAGE;
      let excessiveAmount = currentAmount1to5 - percentAmount;
      result = excessiveAmount;
    } catch (e) {
      console.error(e);
    } finally {
      if (isNaN(result)) {
        result = 0;
      }
    }
    result = Number(result.toFixed(2));
    return result;
  },

  /**
   * Calculates the  Amount of questions 1 to 3
   *
   * @param {Array} technicalDescriptionDatasource - An array of objects that contains the technical description data.
   * @param {Boolean} isEdited - A flag indicating whether the price is edited or not by a non consumer.
   *
   * @returns {Number} The sum of prices in technical description based on the provided conditions.
   */
  getQuestion1to3SumAmount: (technicalDescriptionDatasource, isEdited) => {
    let result = 0;
    try {
      technicalDescriptionDatasource.forEach((x) => {
        let price = x.colPriceSum;
        if (isEdited == true) {
          price = x.colPriceSumEdited;
        }

        let shouldAddPrice =
          TechnicalDescriptionCalculations._isColIds1to3ItemId(x.colId);
        if (shouldAddPrice) {
          result = result + price;
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      if (isNaN(result)) {
        result = 0;
      }
    }
    result = Number(result.toFixed(2));
    return result;
  },

  /**
   * Calculates all related values for the technical description component based on the provided conditions.
   *
   * @param {String} actionType - Info param action Type .
   * @param {String} actionId - Info param action Id.
   * @param {String} stepId - Info param action step Id.
   * @param {Array} technicalDescriptionDatasource - An array of objects that contains the technical description data.
   * @param {Number} pipeDiameter - Info param pipe diameter.
   * @param {String} boilerBrand - Info param pipe brand.
   * @param {String} boilerModel - Info param pipe model.
   * @param {Number} consumptionkw - The consumption used for calculation of the adjusted price (retrieved from previous step action).
   *
   * @returns {Number} The sum of prices in technical description based on the provided conditions.
   */
  getTechnicalDescriptionResultViewModel: (
    actionType,
    actionId,
    stepId,
    technicalDescriptionDatasource,
    pipeDiameter,
    boilerBrand,
    boilerModel,
    consumptionkw
  ) => {
    //checks whether the actionId was the consumer action id or the last step (12) action id, if so, then it means it is the edited sums
    // by a non consumer so we must pass it as an argument

    let value = {
      technicalDescriptionDatasource: technicalDescriptionDatasource,
      pipeDiameter: pipeDiameter,
      boilerBrand: boilerBrand,
      boilerModel: boilerModel,
      consumptionkw: consumptionkw,
      result: {
        consumer: {
          question1to5ExcessiveSumAmount:
            TechnicalDescriptionCalculations.getQuestion1to5ExcessiveSumAmount(
              technicalDescriptionDatasource,
              false
            ),
          totalAmount:
            TechnicalDescriptionCalculations.getTechnicalDescriptionSumPrice(
              technicalDescriptionDatasource,
              false,
              false
            ),
        },
        crm: {
          question1to5ExcessiveSumAmount:
            TechnicalDescriptionCalculations.getQuestion1to5ExcessiveSumAmount(
              technicalDescriptionDatasource,
              true
            ),
          totalAmount:
            TechnicalDescriptionCalculations.getTechnicalDescriptionSumPrice(
              technicalDescriptionDatasource,
              true,
              false
            ),
          maxPowerConsumptionRateValue:
            MaxPowerConsumptionRateHelper.getMaxPricePowerConsumptionRate(
              consumptionkw
            ),
          adjustedAmount: null,
        },
      },
    };

    //GETS THE MAX POWER CONSUMPTION RATE VALUE IF WE HAVE A LARGE NUMBER
    let maxPowerConsumptionRateValue =
      value.result.crm.maxPowerConsumptionRateValue;
    //   maxPowerConsumptionRateValue, value.result.crm.totalAmount, value.result.crm.totalAmount
    value.result.crm.adjustedAmount =
      maxPowerConsumptionRateValue < value.result.crm.totalAmount
        ? maxPowerConsumptionRateValue
        : value.result.crm.totalAmount;

    let resultViewModel = {
      stepActionType: actionType,
      actionId: actionId,
      stepId: stepId,
      value: JSON.stringify(value),
      valueObj: value,
    };

    return resultViewModel;
  },
};
