import React, { Fragment, useEffect, useState } from "react";
import PowerConsumptionValuesStatic from "./PowerConsumptionValuesStatic";
import { Row, Col, Container } from "reactstrap";

export const MaxPowerConsumptionRate = ({
  componentCalculatedValuesViewModel,
}) => {
  let crmValues = componentCalculatedValuesViewModel.valueObj.result.crm;
  let excludedAmount = crmValues.adjustedAmount - crmValues.totalAmount;
  excludedAmount = Number(excludedAmount.toFixed(2));
  let literals = {
    totalAmountAsInput: "Συνολικό καταχωρηθέν ποσό :",
    totalAmountAdjustment: "Το ποσό αναπροσαρμόζεται βάση κλίμακας στα :",
    surplus:
      "Το ποσό βάσει του παραπάνω πίνακα παρουσιάζει πλεόνασμα. Πλεονάζων ποσό :",
  };

  let hasExcludedAmmount = excludedAmount < 0;

  return (
    <Fragment>
      {hasExcludedAmmount && (
        <Fragment>
          <div className="p-3 mb-2 bg-primary text-white">
            <Row>
              <Col md="8">{literals.totalAmountAsInput}</Col>
              <Col md="4">
                <b>[{crmValues.totalAmount}]</b>
              </Col>
              <Col md="8">{literals.surplus}</Col>
              <Col md="4">
                <b>[{Math.abs(excludedAmount)}]</b>
              </Col>
              <Col md="8">{literals.totalAmountAdjustment}</Col>
              <Col md="4">
                <b>[{crmValues.adjustedAmount}]</b>
              </Col>
            </Row>
          </div>
        </Fragment>
      )}
      {hasExcludedAmmount == false && (
        <Fragment>
          <div className="p-3 mb-2 bg-primary text-white">
            <Row>
              <Col md="8">{literals.totalAmountAsInput}</Col>
              <Col md="4">
                <b>[{crmValues.totalAmount}]</b>
              </Col>
            </Row>
          </div>
        </Fragment>
      )}
      <PowerConsumptionValuesStatic />
    </Fragment>
  );
};

export default MaxPowerConsumptionRate;
