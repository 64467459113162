import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import dedaBackground from "../../assets/img/deda-background.jpg";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import Modal from "react-bootstrap/Modal";

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_API_LINK + "/api/auth/token/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [erroremail, setErrorEmail] = useState(false);
  const [errorpass, setErrorPass] = useState(false);
  const [platform, setPlatform] = useState("consumer");
  const [show, setShow] = useState(false);
  const [digit4, setDigit4] = useState(false);
  const [digit4correct, setDigit4correct] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = await loginUser({
      email,
      password,
      platform,
    });

    if (auth.detail == "Incorrect username or password") {
      setErrorEmail(true);
    }
    // The below does not work

    if (auth.errors) {
      setErrorEmail(false);
      setErrorPass(false);
      if (auth.errors == "Email not exists") {
        alert("caught wrong email");
        setErrorEmail(true);
        localStorage.removeItem("token");
        // window.location.replace("/");
      }
      if (auth.errors == "Password Incorrect") {
        alert("caught wrong pass");
        setErrorPass(true);
        localStorage.removeItem("token");
        // window.location.replace("/");
      }
    }
    if (auth.access_token) {
      setToken(String(auth.access_token));
      window.location.replace("/admin/index");
    }
  };

  const postEmail = (e) => {
    e.preventDefault();
    fetch(
      process.env.REACT_APP_API_LINK +
        "/api/consumer/digit4verificationresetpassword/" +
        email +
        "/",
      {
        method: "post",
        headers: new Headers({
          // 'Authorization' : 'Bearer ' + localStorage.getItem('token'),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          setDigit4correct(result.digit4);

          document.getElementById("form1").style.display = "none";
          document.getElementById("form2").style.display = "block";
        } else {
          alert("Δεν υπάρχει χρήστης που να αντιστοιχίζεται σε αυτό το email!");
        }
      });
  };

  const postDigit4 = (e) => {
    e.preventDefault();
    if (digit4 == digit4correct) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/consumer/resetpassword/" +
          email +
          "/",
        {
          method: "put",
          headers: new Headers({
            // 'Authorization' : 'Bearer ' + localStorage.getItem('token'),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            alert(
              "Ο κωδικός σας έχει αλλαχθεί επιτυχώς και σας έχει σταλεί στο email!"
            );
            window.location.reload();
          }
        });
    } else {
      alert(
        "Ο τετραψήφιος κωδικός που συμπληρώσατε δεν αντιστοιχεί με αυτόν που λάβατε στο email!"
      );
    }
  };

  return (
    <div
      className="main-content"
      style={{
        backgroundImage: `url(${dedaBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
        height: "100vh",
      }}
    >
      <AuthNavbar />
      <div className="header py-7 py-lg-8"></div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card
              className="shadow border-0"
              style={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }}
            >
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Κωδικός"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {erroremail ? (
                    <p style={{ color: "orange" }}>
                      To email ή ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε
                      δοκιμάστε ξανά.
                    </p>
                  ) : (
                    <></>
                  )}
                  {errorpass ? (
                    <Input
                      placeholder="Error with password"
                      value="Λανθασμένος κωδικός."
                      type="text"
                      style={{ color: "red" }}
                    />
                  ) : (
                    <Input
                      placeholder="Error with password"
                      value="Λανθασμένος κωδικός."
                      type="hidden"
                      style={{ color: "red" }}
                    />
                  )}

                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Σύνδεση
                    </Button>
                  </div>
                </Form>
                {GlobalParametersHelper.getIsCustomFlorinaEnabled() ==
                  false && (
                  <a
                    className="text-light"
                    href={"https://florina.deda.gr"}
                    style={{ color: "white" }}
                  >
                    <small>Άν είστε κάτοικος Φλώρινας πατήστε εδώ</small>
                  </a>
                )}
                {GlobalParametersHelper.getIsCustomFlorinaEnabled() == true && (
                  <a
                    className="text-light"
                    href={"https://my.deda.gr"}
                    style={{ color: "white" }}
                  >
                    <small>
                      Άν <u>δεν</u> είστε κάτοικος Φλώρινας πατήστε εδώ
                    </small>
                  </a>
                )}
                <br></br>
                <a
                  className="text-light"
                  href={"#"}
                  onClick={() => setShow(true)}
                  style={{ color: "white" }}
                >
                  <small>Ξεχάσατε τον κωδικό σας ;</small>
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <strong style={{ fontSize: "18px", textAlign: "center" }}>
                Επαναφορά Κωδικού Πρόσβασης
              </strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form role="form" id="form1">
              <p>Παρακαλούμε συμπληρώστε το email σας!</p>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={(e) => postEmail(e)}
                >
                  Υποβολή
                </Button>
              </FormGroup>
            </Form>
            <Form role="form" id="form2" style={{ display: "none" }}>
              <p>Παρακαλούμε συμπληρώστε τον τετραψήφιο κωδικό σας!</p>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="4 ψήφιος κωδικός"
                    type="text"
                    onChange={(e) => setDigit4(e.target.value)}
                  />
                </InputGroup>
                <br />
                <span style={{ color: "green" }}>
                  Σας έχει αποσταλεί στο email σας ένας τετραψήφιος κωδικός.
                  Συμπληρώστε τον στο παραπάνω πεδίο, προκειμένου να σας έρθει
                  νέο email με τον καινούργιο σας κωδικό!
                </span>
                <br />
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={(e) => postDigit4(e)}
                >
                  Επαναφορά Κωδικού
                </Button>
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}

// <form onSubmit={handleSubmit}>
//   <label>
//     <p>Email</p>
//     <input type="text" onChange={e => setEmail(e.target.value)}/>
//   </label>
//   <label>
//     <p>Password</p>
//     <input type="password" onChange={e => setPassword(e.target.value)}/>
//   </label>
//   <div>
//     <button type="submit">Submit</button>
//   </div>
// </form>

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
