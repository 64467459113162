import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  
export async function getWorkflowInstanceById(workflowInstanceId) {
  var route = "/api/workflowinstances/instance/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function postUpdateWorkflowInstances(workflowInstanceId, data) {
  var route = "/api/workflowinstances/update/" + workflowInstanceId + "/";
  return postAsync(route, data);
}

export async function postSubmitWorkflowInstances(workflowInstanceId, data) {
  var route = "/api/workflowinstances/submit/" + workflowInstanceId + "/";
  return postAsync(route, data);
}