import {GetEnumsIntoKeyValue} from "../../common/utils"
export function GetDynamicStepActionTypesOptionList() {
    return GetEnumsIntoKeyValue(DynamicStepActionTypesDescription, "title");    
}

export const DynamicStepActionTypes = {
    text : 'text',
    button : 'button',
    checkbox : 'checkbox',
    date : 'date',
    dateReadonly : 'dateReadonly',   
    file : 'file',
    textarea : 'textarea',
    button : 'button',
    number : 'number',
    rating : 'rating',
    mappin : 'mappin',
    dynamicReactSelect : 'dynamicReactSelect',  
    dynamicReactTable : 'dynamicReactTable'    
}	

export const isInputHtmlType = (type) => { 
    let inputTypes = [   
        "checkbox",
        "color",
        "date",
        "datetime-local",
        "email",    
        "hidden",
        "image",
        "month",
        "number",
        "password",
        "radio",
        "range",
        "reset",
        "search",
        "submit",
        "tel",
        "text",
        "time",
        "url",
        "week"
    ];         
    if (!type) {
        return;
    }
    if (inputTypes.indexOf(type)> 0) {
        return true;
    }
    return false;
}

export const DynamicStepActionTypesDescription = {
    text : 'Κείμενο',
    button : 'Πλήκτρο',
    checkbox : 'Ναι/Όχι',
    date : 'Ημερομηνία',   
    file : 'Αρχείο',
    button : 'Κουμπί',
    textarea : 'Πεδίο μεγάλου κειμένου',
    number : 'Αριθμός',
    rating : 'Βαθμολογία',
    mappin : 'Πινέζα σε Χάρτη',
    dynamicReactSelect : 'Δυναμική Λίστα',  
    dynamicReactTable : 'Δυναμικός Πίνακας'    
}	