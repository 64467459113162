const packageJson = require('../package.json');

export const Version = {
    getVersion :() => {
        let version = " - ";
        try {
            version = packageJson?.version??"";
        }catch(ex) {
        }
        return version;
    },
    getVersionDate :() => {
        let versionDate = " - ";
        try {
            versionDate = packageJson?.versiondate??"";
        }catch(ex) {
        }
        return versionDate;
    }
}

const CopyrightVersion = ({})=> {
    let message = 'Copyright © ' + new Date().getFullYear() + 
    ' ver ' + Version.getVersion() + 
    ' date (' + Version.getVersionDate() + ')'     
    return (<>{message}</>)    
}

export default CopyrightVersion