import Select from 'react-select'
import MediaQuery from "react-responsive";

import {
  DynamicStepActionTypes,
} from "../../common/enums/dynamicStepActionTypes.enum";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";


export const EVOReactSelectHelper = {
  isSkip: (stepActionData) => {
    return (
      stepActionData === null ||
      stepActionData.Type != DynamicStepActionTypes.dynamicReactSelect
    );
  },
  getDefaultStepActionMetaDataModel: getDefaultStepActionMetaDataModel,
  getDatasetAndColumnDefinitionsFromStepActionsData: (stepActionData) => {
    let defaultMetaData = getDefaultStepActionMetaDataModel();      
    let metaData = JSON.parse(stepActionData?.MetaDataJson ?? "null")
    if (metaData === null) {
      metaData = defaultMetaData;
    }
    metaData.reactTable = {...defaultMetaData.reactTable, ...metaData.reactTable}    
    return metaData;
  },
  getReactSelectOptionsFromStepActionData : (stepActionData) => {
    let metaData = JSON.parse(stepActionData?.MetaDataJson ?? "null")
    return EVOReactSelectHelper.getReactSelectOptionsFromMetaData(metaData);    
  },
  getReactSelectOptionsFromMetaData : (metaData) => {
    let defaultMetaData = EVOReactSelectHelper.getDefaultStepActionMetaDataModel();     
    if (metaData === null) {
      metaData = defaultMetaData;
    }
    let dataSet = metaData.reactTable.dataset;   
    let result = dataSet.map(function (item) {
      return { 
        value: item.value, 
        label: item.label,
        item : item
      }        
    });
    return dataSet;
  },  
  addAditionalMissingOptionValue : (value, options, valueData) =>{
    if (!valueData){return;}
    if (value == null) {return;}
    let exists = options.some(option => 
      option.value == value);
    if (!exists){
      options.push(valueData);
    }
  },
  getReactSelectDefaultMetaData : (metaData) => {    
    if (metaData === null) {
      return  EVOReactSelectHelper.getDefaultStepActionMetaDataModel();     
    }    
    return metaData;
  },  
  getReactSelectMetaDataFromStepActionData : (stepActionData) => {
    let metaData = JSON.parse(stepActionData?.MetaDataJson ?? "null")
    return metaData;    
  },
  getSelectedValueInfo : (value) => {
    if (value == null){return null;}
    return {value : value.value,  metaData : {valueData: value} };    
  },
  getSelectedOptionFromValue : (value, options) => {
    return options.filter(option => 
      option.value == value)[0]??null;   
  },
  getDefaultStyles : (isEnforcePosition) => {
    let menu = provided => ({ ...provided, zIndex: 99999 })
    if (isEnforcePosition && isEnforcePosition === true){
      menu = provided => ({ ...provided, position: 'relative', zIndex: 99999 })
    }
    let styles = 
      {
        menuPortal: base => ({ ...base, zIndex: 99999 }),                                    
        menu: menu
      }
    return styles
  },
  getDefaultNoYesOptions : () => {
    return [      
      {
        value: false,
        label: "ΟΧΙ",
      },
      {
        value: true,
        label: "ΝΑΙ",
      }
    ];    
  },
  advancedSettingsSearchOptionsType : {
    ENABLED: 'Enabled',
    ENABLED_ONLY_FOR_DESKTOP: 'EnabledOnlyForDesktop',
    DISABLED: 'Disabled',    
  },
  getAdvancedSettingsSearchOptions : () => { 
    return [
      {value: EVOReactSelectHelper.advancedSettingsSearchOptionsType.ENABLED, label: "Ενεργό"},
      {value: EVOReactSelectHelper.advancedSettingsSearchOptionsType.ENABLED_ONLY_FOR_DESKTOP, label: "Ενεργό μόνο σε υπολογιστή"},
      {value: EVOReactSelectHelper.advancedSettingsSearchOptionsType.DISABLED, label: "Ανενεργό"}                                        
  ]},
  Select :({ isFirstOptionDefaultValue, customMetaData, ...fields}) => {    
    isFirstOptionDefaultValue = isFirstOptionDefaultValue || false;    

    let _isClearable = false;
    let _isSearchable = true;
    let _isSearchableMobile = true;
    
    if (customMetaData){
      function setIsClearable(){
        let isClearable = false;
        try{
          let value = customMetaData.isClearable??false;
          if (typeof value == "boolean") {
            isClearable = value;
          }
        }catch(ex){
          console.error(ex);
        }
        _isClearable = isClearable;
      }

      function setIsSearchable(){
        let isSearchableValue = EVOReactSelectHelper.getAdvancedSettingsSearchOptions()[0].value;        
        try{    
          let value = customMetaData.isSearchable??'';    
          value = EVOReactSelectHelper.getAdvancedSettingsSearchOptions().filter(x=>x.value == value)[0]??null;
          if (value){
              isSearchableValue = value.value;
              if (isSearchableValue == EVOReactSelectHelper.advancedSettingsSearchOptionsType.ENABLED){
                _isSearchable = true;
                _isSearchableMobile = true;
              }
              if (isSearchableValue == EVOReactSelectHelper.advancedSettingsSearchOptionsType.ENABLED_ONLY_FOR_DESKTOP){
                _isSearchable = true;
                _isSearchableMobile = false;
              }
              if (isSearchableValue == EVOReactSelectHelper.advancedSettingsSearchOptionsType.DISABLED){
                _isSearchable = false;
                _isSearchableMobile = false;
              } 
          }    
        }catch(ex){
          console.error(ex);
        }        
      }
      setIsClearable();
      setIsSearchable();     
    }

    if (
      fields.defaultValue == null && 
      fields.options && 
      isFirstOptionDefaultValue == true) {
        fields.defaultValue = fields.options[0]??null
    }    
    
    if (fields.isClearable == null){
      fields.isClearable = _isClearable;
    }  
    
    if (fields.isSearchable != null){
      _isSearchable  = fields.isSearchable;
      _isSearchableMobile  = fields.isSearchable;
    }         
      
    return (
      <>
        <MediaQuery
          maxWidth={GlobalParametersHelper.getResponsiveBreakPointWidth()}
        >
          <Select
            styles={EVOReactSelectHelper.getDefaultStyles()}
            {...fields}
            isSearchable = {_isSearchableMobile}
          />
        </MediaQuery>
        <MediaQuery
          minWidth={GlobalParametersHelper.getResponsiveBreakPointWidth()}
        >
          <Select
            styles={EVOReactSelectHelper.getDefaultStyles()}
            {...fields}
            isSearchable = {_isSearchable}
          />
        </MediaQuery>
      </>
    );
  }
    
  

};

export default EVOReactSelectHelper;

function getDefaultStepActionMetaDataModel() {
  return {
    reactTable: {       
      endPointMetadataDefinitions: {
        isEndPoint : false,
        url : "",      
        method : "",
        body : "",    
      },
      value: "value",
      label: "label",
      columnMetadataDefinitions: [
        {
          id: "value",
          title: "Τιμή",
          type: "text",
          isEditable: true,
          defaultNewRowValue: "",
        },
        {
          id: "label",
          title: "Τίτλος",
          type: "text",
          isEditable: true,
          defaultNewRowValue: "",
        },
        {
          id: "__action",
          title: "Ενέργεια",
          type: "action",
          showDelete: true,
          width: 50,
        },
      ],
      initialDataset: [{ value: "001", label: "Title 001" }],
      dataset: [        
      ],
    },
  };
}
