export const MaxPowerConsumptionRateHelper = {
  PowerConsumptionRates : [
    {id: "1", maxKW: 35, amount: 4000},
    {id: "2", maxKW: 50, amount: 6500},
    {id: "3", maxKW: 100, amount: 11500},
    {id: "4", maxKW: 200, amount: 16000},
    {id: "5", maxKW: 99999999, amount: 21500}
  ],
  getMaxPricePowerConsumptionRate : (kw) => {
    const DEFAULT_ID = "5";
    let rates = MaxPowerConsumptionRateHelper.PowerConsumptionRates;    
    const result = rates.find(x => kw <= x.maxKW);
    if (result == null){
      result = rates.find(x => x.id == DEFAULT_ID);
    }     
    return Number(result.amount);  
  },
  /**
   * Retrieves the power consumption rate that was given as input from the provided steps.
   * 
   * @param {Array} steps - An array of objects that contain information about each step.
   * 
   * @returns {Number} The power consumption rate data found in the provided steps.
   */
  getStepActionConsumptionRateData : (steps) => {
    let result = 0;
    try {
      if (!steps) {
        return result;
      }
      const STEP_WITH_ACTION_POWER_CONSUMPTION = "85d20ba6-3ff1-402c-afc2-16704ecffcf4";
      const STEP_ACTION_POWER_CONSUMPTION = "8e8f44f0-e639-4a74-b617-8bca415facca";
      let action = steps
        .find((x) => x.step.Id == STEP_WITH_ACTION_POWER_CONSUMPTION)
        .actions.find((x) => x.action.Id == STEP_ACTION_POWER_CONSUMPTION);
      if (action) {
        result = Number(action.data.Data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (isNaN(result)) {
        result = 0;
      }
    }
    return result;
  }
}

export default MaxPowerConsumptionRateHelper;