import React, { Fragment, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import EVOAlertComponent from "../../../components/Abstract/EvoAlertComponent";
import { WorkflowInstancesHelper } from "../../../common/workflowInstancesHelper";
import { CommonLoading } from "react-loadingg";

import axios from "axios";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { RedirectHelper } from "../../../common/redirectHelper";
import TechnicalDescription from "../../../views/custom/florina/technicalDescriptionComponent/TechnicalDescription";
import {
  SetTechnicalDescriptionStepActionData,
  GetTechnicalDescriptionStepActionIsValidForSubmit,
  TechnicalDescriptionActionIdEnum,
} from "../../custom/florina/technicalDescriptionComponent/TechnicalDescription.Helper";
import { isInputHtmlType } from "../../../common/enums/dynamicStepActionTypes.enum";
import ApplicationHelper from "common/ApplicationHelper";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";
class InstanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      instance: [],
      steps: [],
      files: [],
      enabled: true,
      fileUpload: null,
      buttonCondition: 0,
      consumerType: 0,
      consumerCategory: 0,
      consumerCategoryValue: 0,
      type: "",
      removeActions: 0,
      initialStepsDatas: [], //holds the initial values, used with IsEnforcedReadOnlyAction, so as to allow the user to edit the field if the initial value is null or empty string
    };

    this.changeType = this.changeType.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
  }

  async componentDidMount() {
    const instanceId = this.props.match.params.id;
    let promiseResults = await Promise.all([
      ApiCallerHelper.WorkflowInstances.getWorkflowInstanceById(instanceId),
      ApiCallerHelper.Files.getFilesStatusByInstanceId(instanceId),
    ]);
    let workflowInstancesResult = promiseResults[0];
    let fileStatusResult = promiseResults[1];

    function getIsInstanceInSecondStep(instance) {
      return instance.Status === 2;
    }

    let consumerTypeResult = null;
    if (getIsInstanceInSecondStep(workflowInstancesResult.data.instance)) {
      consumerTypeResult =
        await ApiCallerHelper.Consumer.getConsumerTypeByInstanceId(instanceId);
    }

    function getInitialStepsData(stepsDatas) {
      let initialStepsDatas = stepsDatas
        .map((x) =>
          x.actions.map((y) => ({ id: y.action.Id, data: y.data?.Data ?? "" }))
        )
        .flat();
      return initialStepsDatas;
    }

    this.setState({
      loading: false,
      instanceIsCustomFlorinaFlag:
        workflowInstancesResult.data.isFlorinaFlag ?? false,
      instance: workflowInstancesResult.data.instance,
      steps: workflowInstancesResult.data.stepsInfused,
      fileUpload: null,
      consumerType: 0,
      consumerCategory: 0,
      files: fileStatusResult.data,
      type: consumerTypeResult?.data?.type ?? this.state.type,
      initialStepsDatas: getInitialStepsData(
        workflowInstancesResult.data.stepsInfused
      ),
    });
  }

  saveInstance = async (stepStatus) => {
    // let hasStepStatusRequiredFieldMissing = this.isAllRequiredFieldsWithValue(stepStatus);
    // if (hasStepStatusRequiredFieldMissing){
    //     EVOAlertComponent.Alert({message:"Το πεδίο [" + hasStepStatusRequiredFieldMissing.name + "] είναι κενό"});
    //     return;
    // }
    let workflowInstanceId = this.state.instance.Id;
    var data = this.state.steps[stepStatus - 1];
    data.metaDataConsumerCategory = this.state.consumerCategory;
    let result =
      await ApiCallerHelper.WorkflowInstances.postUpdateWorkflowInstances(
        workflowInstanceId,
        data
      );
    RedirectHelper.RedirectTo.consumer();
  };

  submitInstance = async (stepStatus) => {
    let isCustomFlorinaFlag =
      this.state.instanceIsCustomFlorinaFlag &&
      GlobalParametersHelper.getIsCustomFlorinaEnabled();
    let hasStepStatusRequiredFieldMissing =
      WorkflowInstancesHelper.Action.IsAllRequiredFieldsInStepWithValue(
        this.state.steps,
        stepStatus,
        this.state.files,
        isCustomFlorinaFlag
      );
    if (hasStepStatusRequiredFieldMissing) {
      EVOAlertComponent.Alert({
        message:
          "Το πεδίο [" +
          hasStepStatusRequiredFieldMissing.name +
          "] είναι κενό",
      });
      return;
    }

    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      //CHECK VALIDATION FOR CUSTOM GRID
      if (
        GetTechnicalDescriptionStepActionIsValidForSubmit(
          this.state.steps,
          stepStatus,
          TechnicalDescriptionActionIdEnum.ConsumerActionId
        ) == false
      ) {
        return;
      }

      let workflowInstanceId = this.state.instance.Id;
      this.setState({ removeActions: 1 });
      var data = this.state.steps[stepStatus - 1];
      data.metaDataConsumerCategory = this.state.consumerCategory;
      let result =
        await ApiCallerHelper.WorkflowInstances.postSubmitWorkflowInstances(
          workflowInstanceId,
          data
        );
      RedirectHelper.RedirectTo.reloadPageFromCache();
    }
  };

  ConsumerTypes = {
    HOUSEHOLD: "Οικιακός",
    COMMERCIAL: "Εμπορικός",
    PUBLIC_BUILDING: "Δημόσιο Κτίριο",
  };

  ConsumerCategoriesEnum = {
    DEFAULT: 0,
    SINGLE_HOUSE: 1,
    MULTIPLE_HOUSE_CENTRAL_HEAT: 2,
    SINGLE_HOUSE_APARTMENT_CENTRAL_HEAT_WITH_VOTE: 3,
    SINGLE_HOUSE_APARTMENT_CENTRAL_HEAT_WITHOUT_VOTE: 4,
    SINGLE_HOUSE_APARTMENT_NO_CENTRAL_HEAT: 5,
  };

  getConsumerCategories() {
    return [
      {
        value: this.ConsumerCategoriesEnum.DEFAULT,
        title: "-- Επιλέξτε κατηγορία --",
      },
      {
        value: this.ConsumerCategoriesEnum.SINGLE_HOUSE,
        title: "Μονοκατοικία",
      },
      {
        value: this.ConsumerCategoriesEnum.MULTIPLE_HOUSE_CENTRAL_HEAT,
        title: "Πολυκατοικία με κεντρική θέρμανση",
      },
      {
        value:
          this.ConsumerCategoriesEnum
            .SINGLE_HOUSE_APARTMENT_CENTRAL_HEAT_WITH_VOTE,
        title:
          "Αυτοτελές διαμέρισμα σε πολυκατοικία με κεντρική Θέρμανση με συναίνεση πλειοψηφίας",
      },
      {
        value:
          this.ConsumerCategoriesEnum
            .SINGLE_HOUSE_APARTMENT_CENTRAL_HEAT_WITHOUT_VOTE,
        title:
          "Αυτοτελές διαμέρισμα σε πολυκατοικία με κεντρική Θέρμανση χωρίς συναίνεση πλειοψηφίας",
      },
      {
        value:
          this.ConsumerCategoriesEnum.SINGLE_HOUSE_APARTMENT_NO_CENTRAL_HEAT,
        title: "Αυτοτελές διαμέρισμα σε πολυκατοικία χωρίς κεντρική θέρμανση",
      },
    ];
  }

  dynamicCall(call) {
    if (call == "req_aut") {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/dynamic/autopsy/" +
          this.state.instance.Id +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {});

      this.setState({
        buttonMessage:
          "Αιτηθήκατε για αυτοψία. Θα ενημερωθεί το αρμόδιο τμήμα για το αίτημά σας.",
        buttonCondition: 8,
      });
    }
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  deleteFile = (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          fetch(
            process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
            .then((res) => res.json())
            .then((res) => {
              this.setState({
                loading: false,
                files: res.data,
              });
            });
        });
    }
  };

  async uploadFile(evt, fileId) {
    const formData = new FormData();
    const res = fileId.split("/");

    // Append the file to the formData
    formData.append("file", evt.target.files[0]);

    // Append additional data to formData
    formData.append("serial_number", this.state.instance.SerialNumber);
    formData.append("step_id", res[0]);
    formData.append("action_id", res[1]);


    fetch(process.env.REACT_APP_API_LINK + "/api/files/new/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData
    }).then((data) => {
      axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/files/status/" +
            this.state.instance.Id +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: false,
            files: res.data,
          });
        });
    });
  }

  updateInputValue(evt) {
    if (this.state == null) {
      return;
    }
    let isValueUpdated = SetTechnicalDescriptionStepActionData(
      evt,
      this.state.steps
    );
    if (isValueUpdated == true) {
      return;
    }

    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            if (tempAction.action.Target == "limit14") {
              if (evt.target.value.length > 14) {
                evt.target.value = evt.target.value.substring(
                  0,
                  evt.target.value.length - 1
                );
              }
            }

            if (tempAction.action.Target == "limit9") {
              if (evt.target.value.length > 9) {
                evt.target.value = evt.target.value.substring(
                  0,
                  evt.target.value.length - 1
                );
              }
            }

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
          }
        }
      }
    }
  }

  changeType(event) {
    this.setState({ consumerType: event.target.value });
  }

  changeCategory(event) {
    const select = event.target;
    const option = select.options[select.selectedIndex];
    this.setState({
      consumerCategoryValue: option.value,
      consumerCategory: option.text,
    });
  }

  ImportantDocumentRedirectComponent = () => (
    <>
      <label className="form-control-label" htmlFor="consumer-client">
        Επιπλέον Δικαιολογητικά
      </label>

      <p>
        <a href="https://deda.gr/xrisima-eggrafa/" target="_blank">
          Πηγή εγγράφων
        </a>
      </p>
    </>
  );

  //ADDING LOGIC FOR RENDER VISIBILITY
  _isRenderDynamicDataVisible = (dynamicData) => {
    let isVisible = dynamicData.step.Status == this.state.instance.Status;
    if (isVisible) {
      let stepId = dynamicData.step.id;
      let instanceIsCustomFlorinaFlag = this.state.instanceIsCustomFlorinaFlag;
      isVisible =
        WorkflowInstancesHelper.IsWorfklowInstanceStepEnabledByCustomFlag(
          stepId,
          instanceIsCustomFlorinaFlag
        );
    }

    return isVisible;
  };

  //NOT USED
  _isDynamicDataConditionalAndNotPaused = (dynamicData) => {
    const result = dynamicData.step.Conditional && !this.state.instance.Paused;
    return result;
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    }

    return (
      <>
        <InstanceHeader />
        <div
          className="header pb-4"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          ></CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Φυσικό Αέριο
                          </span>
                          <CardBody>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Α/Α: &nbsp;
                              </span>
                              {this.state.instance.SerialNumber}/
                              {new Date().getFullYear()}
                            </Row>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Ημερομηνία Έναρξης: &nbsp;
                              </span>
                              {Moment(this.state.instance.StartedAt)
                                .locale("el")
                                .format("dddd, DD MMM YYYY")}
                            </Row>
                          </CardBody>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {this.state.steps.map((dynamicData, key) => (
          <Fragment key={key}>
            {this._isRenderDynamicDataVisible(dynamicData) && (
              <>
                {dynamicData.step.Conditional &&
                !this.state.instance.Paused &&
                this.state.instance.Status != 6 &&
                this.state.instance.Status != 8 &&
                this.state.instance.Status != 10 &&
                this.state.instance.Status != 9 ? (
                  <Container className="mt-2" fluid>
                    <Row className="mt-5">
                      <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                              <Col xs="8">
                                <h3 className="mb-0">
                                  {dynamicData.step.Name}
                                </h3>
                              </Col>
                              {this.state.removeActions ? (
                                <> </>
                              ) : (
                                <Col className="text-right" xs="4">
                                  <Button
                                    color="primary"
                                    href="#pablo"
                                    onClick={() => {
                                      this.saveInstance(
                                        dynamicData.step.Status
                                      );
                                    }}
                                    size="sm"
                                  >
                                    Αποθήκευση
                                  </Button>
                                  <Button
                                    color="success"
                                    href="#pablo"
                                    onClick={() => {
                                      this.submitInstance(
                                        dynamicData.step.Status
                                      );
                                    }}
                                    size="sm"
                                  >
                                    Υποβολή
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </CardHeader>
                          <CardBody>
                            {this.state.instance.Status == 2 && (
                              <Row>
                                <Col lg="12">
                                  <p>
                                    <strong>
                                      <i>
                                        Σημείωση σχετικά με τα αρχεία
                                        δικαιολογητικών :
                                      </i>
                                    </strong>
                                  </p>
                                </Col>

                                <Col lg="12">
                                  <p>
                                    Θα πρέπει να αναρτήσετε τα δικαιολογητικά
                                    που αφορούν την κατηγορία που ανήκετε.
                                  </p>
                                </Col>

                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="consumer-client"
                                    >
                                      Τύπος Πελάτη
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="consumer-type"
                                      type="text"
                                      defaultValue={this.state.type}
                                      onChange={this.changeType}
                                      disabled={false}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="9">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="consumer-client"
                                    >
                                      Κατηγορία
                                    </label>
                                    {this.state.type ==
                                      this.ConsumerTypes.HOUSEHOLD && (
                                      <>
                                        <Input
                                          className="form-control-alternative"
                                          id="consumer-type"
                                          type="select"
                                          onChange={this.changeCategory}
                                        >
                                          {this.getConsumerCategories().map(
                                            (consumerCategory, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={consumerCategory.value}
                                                >
                                                  {consumerCategory.title}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Input>

                                        <Col lg="12">
                                          <FormGroup>
                                            {this.state.consumerCategoryValue ==
                                              this.ConsumerCategoriesEnum
                                                .SINGLE_HOUSE && (
                                              <>
                                                <this.ImportantDocumentRedirectComponent />
                                                <p>
                                                  Δεν απαιτούνται επιπλέον
                                                  δικαιολογητικά
                                                </p>
                                              </>
                                            )}

                                            {(this.state
                                              .consumerCategoryValue ==
                                              this.ConsumerCategoriesEnum
                                                .MULTIPLE_HOUSE_CENTRAL_HEAT ||
                                              this.state
                                                .consumerCategoryValue ==
                                                this.ConsumerCategoriesEnum
                                                  .SINGLE_HOUSE_APARTMENT_CENTRAL_HEAT_WITH_VOTE) && (
                                              <>
                                                <label
                                                  className="form-control-label"
                                                  htmlFor="consumer-client"
                                                >
                                                  Επιπλέον Δικαιολογητικά
                                                </label>

                                                <p>
                                                  <a href="https://deda.gr/xrisima-eggrafa/">
                                                    Πηγή εγγράφων
                                                  </a>
                                                </p>

                                                <ul>
                                                  <li>
                                                    Πρακτικό Γενικής Συνέλευσης,
                                                    με τις υπογραφές
                                                    συνιδιοκτητών που
                                                    αντιστοιχούν στις μισές συν
                                                    μία ψήφους (≥ 501 %), από το
                                                    οποίο προκύπτει η απόφαση
                                                    για σύνδεση με το δίκτυο
                                                    φυσικού αερίου και ο ορισμός
                                                    του διαχειριστή ή του
                                                    προσώπου που θα υπογράψει τη
                                                    σύμβαση σύνδεσης. Το
                                                    πρακτικό θα πρέπει να είναι
                                                    θεωρημένο από Δημόσια Αρχή
                                                    για το γνήσιο υπογραφής του
                                                    διαχειριστή.
                                                  </li>
                                                </ul>
                                              </>
                                            )}

                                            {this.state.consumerCategoryValue ==
                                              this.ConsumerCategoriesEnum
                                                .SINGLE_HOUSE_APARTMENT_CENTRAL_HEAT_WITHOUT_VOTE && (
                                              <>
                                                <this.ImportantDocumentRedirectComponent />
                                                <ul>
                                                  <li>
                                                    Αντίγραφο του αιτήματος για
                                                    διενέργεια Γενικής
                                                    Συνέλευσης
                                                  </li>
                                                  <li>
                                                    Γνωστοποίηση αποσύνδεσης
                                                  </li>
                                                </ul>
                                              </>
                                            )}

                                            {this.state.consumerCategoryValue ==
                                              this.ConsumerCategoriesEnum
                                                .SINGLE_HOUSE_APARTMENT_NO_CENTRAL_HEAT && (
                                              <>
                                                <label
                                                  className="form-control-label"
                                                  htmlFor="consumer-client"
                                                >
                                                  Επιπλέον Δικαιολογητικά
                                                </label>

                                                <p>
                                                  <a href="https://deda.gr/xrisima-eggrafa/">
                                                    Πηγή εγγράφων
                                                  </a>
                                                </p>

                                                <ul>
                                                  <li>
                                                    Υπεύθυνη Δήλωση για μη
                                                    ύπαρξη κεντρικής θέρμανσης
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )}
                                    {this.state.type ==
                                      this.ConsumerTypes.COMMERCIAL && (
                                      <>
                                        <Input
                                          className="form-control-alternative"
                                          id="consumer-type"
                                          type="select"
                                          onChange={this.changeCategory}
                                        >
                                          <option value="0">
                                            -- Επιλέξτε κατηγορία --
                                          </option>
                                          <option value="1">
                                            Προσωπική εταιρεία (ΟΕ, ΕΕ)
                                          </option>
                                          <option value="2">ΙΚΕ</option>
                                          <option value="3">ΕΠΕ</option>
                                          <option value="4">ΑΕ</option>
                                          <option value="5">
                                            Ατομική Επιχείρηση
                                          </option>
                                        </Input>

                                        <Col lg="3">
                                          <FormGroup>
                                            {(this.state
                                              .consumerCategoryValue == 1 ||
                                              this.state
                                                .consumerCategoryValue == 2 ||
                                              this.state
                                                .consumerCategoryValue == 3 ||
                                              this.state
                                                .consumerCategoryValue ==
                                                4) && (
                                              <>
                                                <this.ImportantDocumentRedirectComponent />
                                                <ul>
                                                  <li>
                                                    Επικυρωμένο αντίγραφο του
                                                    καταστατικού σύστασης της
                                                    εταιρείας (ή του τελευταίου
                                                    εν ισχύ κωδικοποιημένου
                                                    καταστατικού αν αυτό έχει
                                                    τροποποιηθεί).
                                                  </li>
                                                  <li>
                                                    Γενικό πιστοποιητικό ΓΕΜΗ
                                                    και Πιστοποιητικό ισχύουσας
                                                    εκπροσώπησης, που έχουν
                                                    εκδοθεί εντός των δύο (2)
                                                    τελευταίων μηνών.
                                                  </li>
                                                </ul>
                                              </>
                                            )}

                                            {this.state.consumerCategoryValue ==
                                              5 && (
                                              <>
                                                <label
                                                  className="form-control-label"
                                                  htmlFor="consumer-client"
                                                >
                                                  Επιπλέον Δικαιολογητικά
                                                </label>

                                                <p>
                                                  <a href="https://deda.gr/xrisima-eggrafa/">
                                                    Πηγή εγγράφων
                                                  </a>
                                                </p>

                                                <ul>
                                                  <li>
                                                    Αντίγραφο εντύπου έναρξης
                                                    δραστηριότητας και πιθανών
                                                    μεταβολών αυτής από την
                                                    αρμόδια ΔΟΥ ή έγγραφο από το
                                                    taxis από το οποίο
                                                    προκύπτουν τα στοιχεία
                                                    μητρώου της επιχείρησης.
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )}
                                    {this.state.type ==
                                      this.ConsumerTypes.PUBLIC_BUILDING && (
                                      <>
                                        <Input
                                          className="form-control-alternative"
                                          id="consumer-type"
                                          type="select"
                                          onChange={this.changeCategory}
                                        >
                                          <option value="0">
                                            -- Επιλέξτε κατηγορία --
                                          </option>
                                          <option value="1">Ίδρυμα</option>
                                          <option value="2">Σχολείο</option>
                                          <option value="3">Ιερός Ναός</option>
                                        </Input>

                                        <Col lg="3">
                                          <FormGroup>
                                            {this.state.consumerCategoryValue ==
                                              1 && (
                                              <>
                                                <this.ImportantDocumentRedirectComponent />

                                                <ul>
                                                  <li>
                                                    ΦΕΚ σύστασης και έγκρισης
                                                    του Οργανισμού του Ιδρύματος
                                                  </li>
                                                  <li>
                                                    Επικυρωμένο αντίγραφο του
                                                    ισχύοντος καταστατικού
                                                  </li>
                                                </ul>
                                              </>
                                            )}

                                            {this.state.consumerCategoryValue ==
                                              2 && (
                                              <>
                                                <label
                                                  className="form-control-label"
                                                  htmlFor="consumer-client"
                                                >
                                                  Επιπλέον Δικαιολογητικά
                                                </label>

                                                <p>
                                                  <a href="https://deda.gr/xrisima-eggrafa/">
                                                    Πηγή εγγράφων
                                                  </a>
                                                </p>

                                                <ul>
                                                  <li>
                                                    ΦΕΚ σύστασης σχολικής
                                                    επιτροπής
                                                  </li>
                                                  <li>
                                                    Απόφαση σχολικής επιτροπής
                                                    για σύνδεση του σχολικού
                                                    κτιρίου με το δίκτυο φυσικού
                                                    αερίου και εξουσιοδότηση
                                                    τρίτου φυσικού προσώπου πλην
                                                    του προέδρου της σχολικής
                                                    επιτροπής να υπογράψει τη
                                                    σύμβαση φυσικού αερίου (Αν
                                                    το σχολείο ανήκει στο Δήμο,
                                                    τότε απαιτείται απόφαση
                                                    Δημοτικού Συμβουλίου και
                                                    εξουσιοδότηση τρίτου φυσικού
                                                    προσώπου πλην του Δημάρχου
                                                    να υπογράψει την σύμβαση
                                                    σύνδεσης φυσικού αερίου)
                                                  </li>
                                                </ul>
                                              </>
                                            )}

                                            {this.state.consumerCategoryValue ==
                                              3 && (
                                              <>
                                                <this.ImportantDocumentRedirectComponent />

                                                <ul>
                                                  <li>
                                                    ΦΕΚ ίδρυσης του Ιερού Ναού,
                                                    εάν έχει συσταθεί μετά την
                                                    05-01-1980
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}

                            <Form>
                              <div className="pl-lg-4">
                                <Row>
                                  {dynamicData.actions.map(
                                    (dynamicActions, key) => (
                                      <Fragment key={key}>
                                        {dynamicActions.action.RunTime ==
                                          "consumer" &&
                                          WorkflowInstancesHelper.IsWorfklowInstanceStepActionEnabledByCustomFlag(
                                            dynamicActions.action.Id,
                                            this.state
                                              .instanceIsCustomFlorinaFlag
                                          ) && (
                                            <>
                                              <Col lg="6">
                                                <FormGroup>
                                                  {/* {this.state.instance.Status == 2 ? 
                                                                            <> </> : <>  */}
                                                  {/* </>} */}
                                                  {dynamicActions.action.Type ==
                                                    "file" && (
                                                    <>
                                                      {/* {this.state.instance.Status == 2 ? 
                                                                                    <> </> : <>  */}
                                                      <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                      >
                                                        {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                          dynamicActions.action
                                                        )}
                                                      </label>
                                                      <Row>
                                                        <Col lg="10">
                                                          <label
                                                            className="btn btn-primary"
                                                            htmlFor={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                          >
                                                            Επιλογή αρχείου
                                                          </label>
                                                          <Input
                                                            className="form-control-alternative"
                                                            // defaultValue={ dynamicActions.data.Data }
                                                            id={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                            // placeholder={dynamicActions.action.Description}
                                                            style={{
                                                              visibility:
                                                                "hidden",
                                                            }}
                                                            type={
                                                              dynamicActions
                                                                .action.Type
                                                            }
                                                            // disabled={!dynamicActions.action.Required}
                                                            onChange={(evt) =>
                                                              this.uploadFile(
                                                                evt,
                                                                dynamicData.step
                                                                  .Id +
                                                                  "/" +
                                                                  dynamicActions
                                                                    .action.Id
                                                              )
                                                            }
                                                          />
                                                        </Col>
                                                        {/* <Button
                                                                                                color="primary"
                                                                                                onClick={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                size="sm"
                                                                                            >
                                                                                                Υποβολή
                                                                                            </Button> */}
                                                      </Row>

                                                      <Table
                                                        className="align-items-center table-flush"
                                                        responsive
                                                      >
                                                        <thead className="thead-light">
                                                          <tr>
                                                            <th scope="col">
                                                              Ονομα
                                                            </th>
                                                            <th scope="col">
                                                              Ημερομηνια
                                                              Υποβολης
                                                            </th>
                                                            <th scope="col">
                                                              Ενεργειες
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {this.state.files.map(
                                                            (
                                                              dynamicFiles,
                                                              key
                                                            ) => (
                                                              <Fragment
                                                                key={key}
                                                              >
                                                                {dynamicActions
                                                                  .action.Id ==
                                                                dynamicFiles.ActionId ? (
                                                                  <>
                                                                    <tr>
                                                                      <td>
                                                                        {
                                                                          dynamicFiles.FileName
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dynamicFiles.UploadDate
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        <Button
                                                                          color="primary"
                                                                          onClick={() => this.viewFile(dynamicFiles.Id)}
                                                                          size="sm"
                                                                          title="Προβολή"
                                                                        >
                                                                          <i className="fas fa-eye" />
                                                                        </Button>
                                                                        <Button
                                                                          color="danger"
                                                                          onClick={() => {
                                                                            this.deleteFile(
                                                                              dynamicFiles.Id
                                                                            );
                                                                          }}
                                                                          size="sm"
                                                                          title="Διαγραφή"
                                                                        >
                                                                          <i className="fas fa-trash" />
                                                                        </Button>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </Fragment>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </Table>
                                                      {/* </> } */}
                                                    </>
                                                  )}
                                                  {dynamicActions.action.Type ==
                                                    "button" && (
                                                    <>
                                                      <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                      >
                                                        {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                          dynamicActions.action
                                                        )}
                                                      </label>
                                                      <Button
                                                        color="primary"
                                                        onClick={(evt) =>
                                                          this.dynamicCall(
                                                            dynamicActions.data
                                                              .Data
                                                          )
                                                        }
                                                        size="sm"
                                                      >
                                                        {
                                                          dynamicActions.action
                                                            .Description
                                                        }
                                                      </Button>
                                                      {this.state
                                                        .buttonCondition ? (
                                                        <p>Περίπτωση Α</p>
                                                      ) : (
                                                        <p>Περίπτωση B</p>
                                                      )}
                                                    </>
                                                  )}
                                                  {dynamicActions.action.Type ==
                                                    "paragraph" && (
                                                    <>
                                                      <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                      >
                                                        {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                          dynamicActions.action
                                                        )}
                                                      </label>
                                                      <p></p>
                                                    </>
                                                  )}
                                                  {isInputHtmlType(
                                                    dynamicActions.action.Type
                                                  ) && (
                                                    <>
                                                      <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                      >
                                                        {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                          dynamicActions.action
                                                        )}
                                                      </label>
                                                      <Input
                                                        className="form-control-alternative"
                                                        defaultValue={
                                                          dynamicActions.data
                                                            .Data
                                                        }
                                                        id={
                                                          dynamicData.step.Id +
                                                          "/" +
                                                          dynamicActions.action
                                                            .Id
                                                        }
                                                        placeholder={
                                                          dynamicActions.action
                                                            .Description
                                                        }
                                                        type={
                                                          dynamicActions.action
                                                            .Type
                                                        }
                                                        //disabled={!dynamicActions.action.Required}
                                                        disabled={WorkflowInstancesHelper.IsEnforcedReadOnlyAction(
                                                          dynamicActions.action
                                                            .Id,
                                                          this.state
                                                            .initialStepsDatas
                                                        )}
                                                        onChange={(evt) =>
                                                          this.updateInputValue(
                                                            evt
                                                          )
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                </FormGroup>
                                              </Col>

                                              <TechnicalDescription
                                                steps={this.state.steps}
                                                title={WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                  dynamicActions.action
                                                )}
                                                actionType={
                                                  dynamicActions.action.Type
                                                }
                                                actionId={
                                                  dynamicActions.action.Id
                                                }
                                                stepId={dynamicData.step.Id}
                                                actionDataValue={
                                                  dynamicActions.data.Data
                                                }
                                                onUpdateCallback={
                                                  this.updateInputValue
                                                }
                                                // isReadOnly = {true}
                                              />
                                            </>
                                          )}
                                      </Fragment>
                                    )
                                  )}
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <Container className="mt-2" fluid>
                    <Row className="mt-5">
                      <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                          <CardBody>
                            <Form>
                              <div className="pl-lg-4">
                                <Row>
                                  <Col lg="12">
                                    <p style={{ fontSize: "18px" }}>
                                      Θα ενημερωθείτε από τον Έλεγχο Πορείας για
                                      το επόμενο στάδιο της αίτησης που θα
                                      χρειαστεί να συμπληρώσετε.
                                    </p>
                                    <Button
                                      color="primary"
                                      href={
                                        "/consumer/instance/" +
                                        this.state.instance.Id
                                      }
                                      size="lg"
                                    >
                                      Έλεγχος πορείας
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                )}
              </>
            )}
          </Fragment>
        ))}
      </>
    );
  }
}

export default InstanceDetails;
