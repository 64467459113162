export const RegularExpressions = {
    //ONLY_NUMBER_CHARACTER : /[0-9]/,
    ONLY_NUMBERS : /^\d+$/,   
    ONLY_NUMBERS_COMMA_DOT : /^[0-9,.]*$/ ,   
    ONLY_LETTERS : /^[a-zA-Z]+$/,
    NOT_NUMBERS : /^([^0-9]*)$/,
    EMAIL : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    
}	

export default RegularExpressions;