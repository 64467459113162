import {
  React,
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import {
  DynamicInputValidatonType,
  GetDynamicInputValidatonTypeOptionList,
} from "../../common/enums/dynamicInputValidationTypes.enum";
import RegularExpressions from "../../common/enums/regularExpressions.enum";

import { Input } from "reactstrap";
export const EvoInput = {
  constants: {
    defaultMinLengthValue: 0,
    defaultMaxLengthValue: 2500,
  },
  Input: ({
    metaData,
    defaultValue,
    defaultNullValue,
    validationTypeOption,
    ...fields
  }) => {
    const [value, setValue] = useState(defaultValue);

    const getDefaultValueIfNull = (value) => {
      let _defaultNullValue =
        defaultNullValue ?? metaData?.defaultNullValue ?? null;

      let isValueNullOrEmptyString = (value ?? "".trim()) === "";
      let hasDefaultValue = _defaultNullValue !== null;
      if (isValueNullOrEmptyString && hasDefaultValue) {
        return _defaultNullValue;
      }

      return value;
    };

    const onChange = fields.onChange;
    const handleValueOnChange = (event, ...fields) => {
      let newValue = event.target.value;
      newValue = getDefaultValueIfNull(newValue);
      newValue = handleMaxLength(newValue);
      setValue(newValue);
      event.target.value = newValue;
      if (onChange) {
        onChange(event, ...fields);
      }
    };

    const handleMaxLength = (value) => {
      let newValue = value?.slice(0, fields.maxLength) ?? "";
      return newValue;
    };

    const getInputPattern = () => {
      let _validationTypeOption =
        validationTypeOption ?? metaData?.validationTypeOption ?? "";
      let dynamicInputValidationType =
        DynamicInputValidatonType[_validationTypeOption];
      switch (dynamicInputValidationType) {
        // case DynamicInputValidatonType.EMAIL:
        //   return RegularExpressions.EMAIL;
        //   break;
        // case DynamicInputValidatonType.VAT_NUMBER_GR:
        //   return RegularExpressions.ONLY_NUMBERS;
        //   break;
        case DynamicInputValidatonType.INTEGER_ONLY:
          return RegularExpressions.ONLY_NUMBERS;
          break;
        case DynamicInputValidatonType.DECIMAL:
          return RegularExpressions.ONLY_NUMBERS_COMMA_DOT;
          break;
        case DynamicInputValidatonType.ALPHABET:
          break;
        case DynamicInputValidatonType.ALPHABET_SYMBOLS:
          return RegularExpressions.NOT_NUMBERS;
          break;
        default:
          return fields.pattern ?? null;
      }
    };
    const pattern = getInputPattern();
    const isInputPatternValue = (value) => {
      if (!pattern) {
        return true;
      }
      let res = pattern.test(value);
      return res;
    };

    const onKeyPressValidatePattern = (e) => {
      if (!pattern) {
        return true;
      }
      let res = !pattern.test(e.key);
      if (res) {
        e.preventDefault();
      }
    };

    const onPasteValidatePattern = (e) => {
      let clipboardData = e.clipboardData || window.Clipboard;
      let pastedData = clipboardData.getData("Text");
      if (!isInputPatternValue(pastedData)) {
        e.preventDefault();
        return;
      }
      pastedData = getDefaultValueIfNull(pastedData);
      pastedData = handleMaxLength(pastedData);
      e.clipboardData.setData("text/plain", pastedData);
    };

    fields.maxLength =
      fields.maxLength ||
      (metaData?.maxLength ?? EvoInput.constants.defaultMaxLengthValue);
    fields.minLength =
      fields.minLength ||
      (metaData?.minLength ?? EvoInput.constants.defaultMinLengthValue);
    fields.onKeyPress = fields.onKeyPress || onKeyPressValidatePattern;
    fields.onPaste = fields.onPaste || onPasteValidatePattern;
    fields.onChange = handleValueOnChange;
    fields.defaultValue = getDefaultValueIfNull(value);
    return <Input {...fields}></Input>;
  },
};
export default EvoInput;
